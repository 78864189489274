<template>
    <div class="years-range">
        <div class="range">
            <span class="left" v-bind:style="{ width: minGap + '%' }"></span>
            <span class="right" v-bind:style="{ width: maxGap + '%' }"></span>
        </div>
        <div class="years">
            <div class="from">
                <b-field label="From">
                    <b-input size="is-large" v-model="currentMin" v-on:blur="minChanged"/>
                </b-field>
            </div>
            <div class="to">
                <b-field label="To">
                    <b-input size="is-large" v-model="currentMax" v-on:blur="maxChanged"/>
                </b-field>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'YearsRange',
        props: {
            min: {type: Number, default: 0},
            initialMin: Number,
            max: {type: Number, default: 4000},
            initialMax: Number,
        },

        data: () => {
            return {
                currentMin: 1900,
                currentMax: 2018,

                lastViableMin: 1900,
                lastViableMax: 2018,
            }
        },

        watch: {
            currentMin: function (newVal, oldVal) {
                if (oldVal > 0) {
                    if (this.$_.inRange(newVal, this.min, this.max+1)) {
                        this.lastViableMin = newVal;
                    }
                }
            },
            currentMax: function (newVal, oldVal) {
                if (oldVal < 4000) {
                    if (this.$_.inRange(newVal, this.min, this.max+1)) {
                        this.lastViableMax = newVal;
                    }
                }
            }
        },

        computed: {
            currentMinNumber() {
                return parseInt(this.currentMin, 10);
            },

            currentMaxNumber() {
                return parseInt(this.currentMax, 10);
            },

            yearsGap() {
                return this.max - this.min;
            },

            minGap() {
                return ((this.currentMinNumber - this.min) * 100) / this.yearsGap;
            },

            maxGap() {
                return ((this.max - this.currentMaxNumber) * 100) / this.yearsGap;
            }
        },

        mounted() {
            this.reset();
        },

        methods: {

            reset() {
                this.currentMin = this.initialMin || this.min;
                this.currentMax = this.initialMax || this.max;

                this.lastViableMin = this.min;
                this.lastViableMax = this.max;
            },

            onChanged() {
                this.$emit('range-changed', {min: this.currentMinNumber, max: this.currentMaxNumber})
            },

            minChanged(e) {
                const val = this.currentMinNumber;
                if (!this.$_.inRange(val, this.min, this.max+1) || val > this.lastViableMax) {
                    window.setTimeout(() => {
                        this.currentMin = this.lastViableMin;
                        this.onChanged();
                    }, 100);
                } else {
                    this.onChanged();
                }
            },

            maxChanged(e) {
                const val = this.currentMaxNumber;
                if (!this.$_.inRange(val, this.min, this.max+1) || val < this.lastViableMin) {
                    window.setTimeout(() => {
                        this.currentMax = this.lastViableMax;
                        this.onChanged();
                    }, 100);
                } else {
                    this.onChanged();
                }
            },
        }
    }
</script>
