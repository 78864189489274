<template>
    <div class="select-group">
        <b-collapse class="card" :open="false">
            <div slot="trigger" slot-scope="props" class="card-header">
                <p class="card-header-title">
                    <b-checkbox
                            class="is-blue-fill is-capitalized"
                            v-bind:class="{light: isRootHalfChecked}"
                            size="is-medium"
                            v-model="rootSelected"
                            v-on:input="rootChecked">
                        {{title}}
                    </b-checkbox>
                </p>
                <a class="card-header-icon">
                    <span class="count" v-if="selectedCount > 0">{{selectedCount}}</span>
                    <b-icon
                            :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                </a>
            </div>
            <div class="card-content">
                <ul class="categories">
                    <li class="category" v-for="cat in cats" v-bind:key="cat">
                        <b-checkbox
                                class="is-blue is-capitalized"
                                size="is-medium"
                                v-model="cat[1]" v-on:input="categoryChecked">
                            {{cat[0]}}
                        </b-checkbox>
                    </li>
                </ul>
            </div>
        </b-collapse>
    </div>
</template>

<script>
    export default {
        name: 'select-group',

        data: () => {
            return {
                rootSelected: false,
                cats: []
            }
        },

        computed: {
            isRootHalfChecked: function () {
                return this.cats.length > 0 && this.selectedCount > 0 && this.selectedCount < this.cats.length
            },

            selectedCount: function() {
                let count = 0;
                for (let i in this.cats) {
                    if (this.cats[i][1]) {
                        count++;
                    }
                }

                return count;
            }
        },

        mounted() {
            for (let i in this.categories) {
                this.cats.push([this.categories[i], false])
            }
        },

        methods: {
            rootChecked(val) {
                for (let i in this.cats) {
                    this.cats[i][1] = val;
                }

                this.$forceUpdate();
            },

            categoryChecked(val) {
                if (this.selectedCount === 0) {
                    this.rootSelected = false;
                } else if (this.selectedCount === this.cats.length) {
                    this.rootSelected = true;
                }
            },

            selectedCategories() {
                let ret = [];

                for (let i in this.cats) {
                    if (this.cats[i][1]) {
                        ret.push(this.cats[i][0])
                    }
                }
                
                return ret
            }
        },

        props: {
            title: String,
            categories: Array
        }
    }
</script>
