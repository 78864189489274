<template>
    <div class="content map-content columns mobile-padding">
        <div class="column is-10 is-offset-1">
            <h1>About My Academic Profile</h1>

            <p>Developing an Academic career is a multi-dimensional task. There are the personal, organizational and societal issues which evolve with opportunities. These are primarily subjective. There are, on the other hand, the ‘hardcore` issues concerning academic achievements and yield which are quantitative become increasingly accessible from open sources.</p>

            <p>The amount of papers and citations are among the primary indications for academic impact. However, is it necessarily right that a scholar with 10,000 citations is better than a one cited only 2,000 times? Is it the final number which matters or the way of achieving it?</p>

            <p>We believe that both matter.</p>

            <p>For example, at first glance, the number of citations looks like a adequate measure of academic impact; however, it is well understood that it is only a gross number which needs interpretation. People develop their research career in different ways, which need to be weighted in. Furthermore, it is essential to understand how a certain number was built up in time and how it is composed.</p>

            <p>In the academic community, there are two main extreme types of researchers: Collaborators and Individualists. Collaborators promote science by working with other people, believing that a shift in a paradigm needs an accumulation of evidence from a wide community of trustable researchers. Current research grant schemes, such as the European Research program, promote collaboration between large multi-national groups. The group of Individuals represents those working alone to develop their unique ideas. They believe that a scientific breakthrough can be achieved by thinking differently than other researchers in their field. Research grant schemes are promoting excellent individuals, such as the  Personal ERC program of the European Research program.</p>

            <p>Most people develop their research and publication strategies by combining different proportions of Collaborative and Individual work. Furthermore, these strategies differ in an academic career following organizational and individual changes. By allowing for a comparison between different researchers, implementing various research and publication strategies, there is a need to develop a common denominator. That denominator is the quantification of the “Relative Contribution” of an author for each article and its impact.</p>

            <p>High Relative Contribution (RC) may be gained from a few single-authored articles or numerous multiple-authored articles. The sum of RC reflects the work of Collaborators and Individualists similarly than the accumulated number of articles.</p>

            <p>Weighting the number of citations gained for each article according to RC also allows for a better comparison between researchers adopting different research and publication strategies.</p>

            <p>For example, a Collaborator with 10,000 citations may yield a weighted number of citations of 2,000 from a large number of articles, a number which can be equal to the weighted number of citations gained by an Individualist from a small number of articles. The difference between the two reflects the type of research strategy adopted during different phases of one’s career.</p>

            <p>Better understanding a research yield and impact is thus gained by using RC and also of the type and mode of progress. A young researcher, based on the understanding of RC, may better plan their career by adjusting a combination of collaborative and individualistic work in an attempt to reach a specific impact attributed to them.</p>

            <p>Many institutions would prefer to see a career characterized by a certain component of individualistic research. Looking at the ‘tension’ between the accumulated number of articles, citations, the RC and weighted number of citations would facilitate the identification of the individualistic part of the impact and may link it to phases in the faculty member’s career development - reflecting ranks and organizational roles.</p>

            <p>A comparison is a fundamental tool in assessing achievements. It is a tool for the individual for their evaluation and planning. It is a tool for promotional committees, as the only specific but essential part of their evaluation due to its objectivity.</p>

            <p><strong>My Academic Profile</strong> is a tool that considers many parameters. Thus, facilitating improvement in assessing research impact progress. Time elapsed since publications, the number of authors, the position of an author in the list of authors, the journal impact factor, and the seniority of co-authors are among the parameters influencing the determination of an author impact.</p>

            <p>We suggest ways of incorporating these considerations in building ones’ Academic profile. This may be used to analyze progress along the Academic career as well as for comparing achievements and impacts of researchers within the same field or between sub-fields within a faculty.</p>

            <p>We provide a system using open source information which allows one to assess their profiles by comparing with similar profiles based on the same data and method.</p>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'AboutPage',
        metaInfo: {
            title: 'About, Introduction and How Profile is Built',
        },
    }
</script>
