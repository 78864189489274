<template>
    <div class="columns signup map-form mobile-padding">
        <div class="column is-4 is-offset-4">

            <router-link to="/" class="logo">
                <img src="../assets/logo-icon.svg" alt="" class="logo-icon">
                <img src="../assets/logo-type.svg" alt="" class="logo-type">
            </router-link>

            <div class="sep"></div>

            <div class="actions">
                <a href="#void" class="button is-large is-google" :title="socialButtonText + ' with google'" @click="googleSignUp"><span class="icon"><img src="../assets/google.svg"/></span><span>{{socialButtonText}} With Google</span></a>
                <a href="#void" class="button is-large is-facebook"><span class="icon"><img src="../assets/facebook.svg"/></span><span>{{socialButtonText}} With Facebook</span></a>
            </div>

            <div class="sep"></div>

            <form v-on:submit.prevent="submit">
                <b-field
                        label="Email"
                        :type="email.isError ? 'is-danger' : ''"
                        :message="email.isError ? email.message : ''"
                >
                    <b-input size="is-large"
                             placeholder="Email"
                             type="email"
                             v-model="email.value"
                             v-on:input="recheck"
                             :aria-invalid="email.isError"
                             required></b-input>
                </b-field>

                <b-field label="Password"
                         :type="password.isError ? 'is-danger' : ''"
                         :message="password.isError ? password.message : ''"
                >
                    <b-input type="password"
                             placeholder="Password"
                             size="is-large"
                             password-reveal
                             v-model="password.value"
                             v-on:input="recheck"
                             :aria-invalid="password.isError"
                             equired>
                    </b-input>
                </b-field>

                <div class="field newsletter" v-if="isSignupPage">
                    <b-checkbox
                            class="is-small is-blue"
                            v-model="newsletter"
                    >Please send me news about new analysis and offers
                    </b-checkbox>
                </div>

                <button class="button cta-button is-large is-fullwidth">{{loginButtonText}}</button>
            </form>

            <div class="notes" v-if="isSignupPage">
                <p>By signing up, you agree to our <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>.</p>
                <p>Already have an account?
                    <router-link :to="{name: 'Login'}">LOGIN</router-link>
                </p>
            </div>
            <div class="notes" v-else>
                <p>New User?
                    <router-link :to="{name: 'SignUp'}">SIGNUP</router-link>
                </p>
            </div>
        </div>
        <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    </div>
</template>

<script>
    export default {
        name: 'SignUpPage',

        data: () => {
            return {
                isLoading: false,
                submittedOnce: false,

                email: {
                    value: '',
                    message: 'Please type in a valid email',
                    isError: false,
                },

                password: {
                    value: '',
                    message: 'Please type your password (min 8 chars)',
                    isError: false,
                },

                newsletter: false
            }
        },

        computed: {
            isSignupPage: function () {
                return this.$route.path === '/signup';
            },
            socialButtonText: function () {
                return this.isSignupPage ? 'Signup' : 'Login'
            },
            loginButtonText: function () {
                return this.isSignupPage ? 'Continue' : 'Login'
            },
        },

        methods: {

            validateForm() {
                let error = false;
                this.email.isError = false;
                this.password.isError = false;
                const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


                if (!emailRegex.test(this.email.value)) {
                    this.email.isError = true;
                    error = true;
                }

                if (this.password.value.length < 8) {
                    this.password.isError = true;
                    error = true;
                }


                return error;
            },

            recheck() {
                if (this.submittedOnce) {
                    this.validateForm();
                }
            },


            submit() {
                this.submittedOnce = true;
                if (!this.validateForm()) {
                    const params = {
                        email: this.email.value,
                        password: this.password.value,
                        newsletter: this.newsletter
                    };

                    this.isLoading = true;
                    this.$http.post(this.$api.url('/signup'), params).then(response => {

                        if (sessionStorage) {
                            sessionStorage.setItem('oid', response.body.oid);
                            sessionStorage.setItem('x-map-auth', response.body.sid)
                        }

                        window.setTimeout(() => {
                            this.isLoading = false;
                            this.$router.push({name: 'Profiles'})
                        }, 1000);


                    }, response => {
                        // error callback
                        this.isLoading = false;
                        console.log(response);
                        this.$toast.open({
                                             duration: 4000,
                                             message: `Oops Error just happened, please try again or contact support`,
                                             type: 'is-danger'
                                         })
                    });
                }
            },

            googleSignUp() {
                const that = this;
                this.isLoading = true;

                this.$gAuth.signIn(function (user) {
                    //on success
                    that.$http.post(that.$api.url('/auth/google'), {code: user.getAuthResponse().id_token}).then(function (response) {

                        if (sessionStorage) {
                            sessionStorage.setItem('oid', response.body.oid);
                            sessionStorage.setItem('x-map-auth', response.body.sid)
                        }

                        window.setTimeout(() => {
                            this.isLoading = false;
                            this.$router.push({name: 'Profiles'})
                        }, 1000);

                    }, response => {
                        // error callback
                        this.isLoading = false;
                        console.log(response);
                        this.$toast.open({
                                             duration: 4000,
                                             message: `Oops Error just happened, please try again or contact support`,
                                             type: 'is-danger'
                                         })
                    })

                }, function (error) {
                    //on fail do something
                    console.error(error);
                    this.isLoading = false;
                })
            }
        }
    }
</script>