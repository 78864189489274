<template>
    <div class="avg-tool">
        <h1>Average Profiles Tool</h1>

        <b-field class="file" label="Click on the icon to choose csv files for upload">
            <b-upload
                    v-model="files"
                    accept="text/csv"
                    :multiple="true"
                    v-on:input="handleFiles()"
            >
                <b-icon icon="upload" size="is-medium"></b-icon>
            </b-upload>
        </b-field>

        
        <b-field grouped v-for="(file, index) in files" :data-pos="index">

            <b-input :value="file.name" :key="file.name" disabled></b-input>
            <b-input placeholder="First Name"></b-input>
            <b-input placeholder="Other Name"></b-input>
            <b-input placeholder="Last Name"></b-input>

            <p class="control">
                <button class="button is-danger" @click="deleteFile(index)">Delete</button>
            </p>
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'SandboxPage',

        data: () => {
            return {
                files: [],
                details: [],
                uploadTokens: null,
            }
        },

        methods: {
            handleFiles() {
                for (let file in this.files) {
                    console.log(file)
                }
                
                // console.log(this.files);
                // this.files[0].TTT = 'aaaa';
                // console.log(this.files)
            },

            deleteFile(pos) {
                this.files.splice(pos, 1);
                this.$forceUpdate()
            },
        },
    }
</script>

<style scoped>

    .avg-tool h1 {
        margin-bottom: 16px;
    }

</style>