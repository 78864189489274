import ChartColors from './colors'

export const ChartsConfig = {
    'avg-article-chart': {
        title: {text: null},
        series: [
            {
                type: 'area',
                data: [],
                name: 'Average no. of Articles per year',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            }
        ]
    },
    'author-count-chart': {
        title: {text: null},
        tooltip: {
            pointFormat: '{point.y}',
            valueSuffix: ' Authors',
            valueDecimals: 0,
        },
        series: [
            {
                id: 'main-series',
                type: 'scatter',
                data: [],
                name: 'No. of Authors',
                color: ChartColors.green,
                // lineWidth: 1,
                // stacking: 'normal',
                marker: {
                    lineColor: ChartColors.greenOpacity,
                    lineWidth: 8
                }
            },
            // {
            //     type: 'sma',
            //     linkedTo: 'main-series',
            //     dashStyle: 'ShortDashDot',
            //     colorIndex: ChartColors.green,
            //     params: {
            //         period: 5
            //     }
            // },

        ]
    },
    'first-author-chart': {
        chart: {
            type: 'bubble',
        },
        title: {text: null},
        yAxis: {
            min: 0
        },
        tooltip: {
            pointFormat: 'year {point.x} first author in {point.y}',
            valueSuffix: ' articles',
            valueDecimals: 0,
        },
        series: [
            {
                data: [],
                name: 'First Author',
                color: ChartColors.turquoiseBlue,
            },
        ]
    },
    'sum-first-author-chart': {
        title: {text: null},
        series: [
            {
                type: 'area',
                data: [],
                name: 'Sum of First Author',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            },
        ]
    },

    'sole-author-chart': {
        chart: {
            type: 'bubble',
        },
        title: {text: null},
        yAxis: {
            min: 0
        },
        tooltip: {
            pointFormat: 'year {point.x} sole author in {point.y}',
            valueSuffix: ' articles',
            valueDecimals: 0
        },
        series: [
            {
                data: [],
                name: 'Sole Author',
                color: ChartColors.green,
            },
        ]
    },

    'sum-sole-author-chart': {
        title: {text: null},
        series: [
            {
                type: 'area',
                data: [],
                name: 'Sum of Sole Author',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
        ]
    },


    'sum-citations-chart': {
        title: {text: null},
        series: [
            {
                id: 'main-series',
                type: 'area',
                data: [],
                name: 'Sum of Citations',
                color: ChartColors.blue
            },
        ]
    },

    'article-count-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Accumulated no. of Papers',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: 'No. of Articles according to relative contribution (first author only)',
                color: ChartColors.green,
                fillOpacity: ChartColors.greenOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: 'No. of Articles according to relative contribution (first and last author)',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },

        ]
    },

    'citation-per-year-chart': {
        title: {text: null},
        series: [
            {
                type: 'column',
                data: [],
                name: 'The number of Citations per each article is divided by the number of years since publication',
                color: ChartColors.blueOpacity,
                borderColor: ChartColors.blue,
                borderWidth: 1,
                pointWidth: 8
            },
        ]
    },

    'citation-contrib-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first author)',
                color: ChartColors.green,
                fillOpacity: ChartColors.greenOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first and last author)',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
        ]
    },

    'citation-contrib-journal-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first author) + journal',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first and last author) + journal',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            },
        ]
    },

};


export const CompareConfig = {

    'article-count-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Accumulated no. of Papers',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] Accumulated no. of Papers',
                color: ChartColors.orange,
                fillOpacity: ChartColors.orangeOpacityValue,
            },
        ]
    },

    'article-count-rc-a-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'scatter',
                data: [],
                name: 'No. of Articles according to RC-A',
                color: ChartColors.green,
                fillOpacity: ChartColors.greenOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] No. of Articles according to RC-A',
                color: ChartColors.classicOrange,
                fillOpacity: ChartColors.classicOrangeOpacityValue,
            },
        ]
    },

    'article-count-rc-b-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'No. of Articles according to RC-B',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] No. of Articles according to RC-B',
                color: ChartColors.yellow,
                fillOpacity: ChartColors.yellowOpacityValue,
            },
        ]
    },

    'sum-citations-chart': {
        title: {text: null},
        series: [
            {
                id: 'main-series',
                type: 'scatter',
                data: [],
                name: 'Sum of Citations',
                color: ChartColors.blue
            },
            {
                id: 'main-series',
                type: 'area',
                data: [],
                name: '[Leaders] Sum of Citations',
                color: ChartColors.orange
            }
        ]
    },

    'citation-per-year-chart': {
        title: {text: null},
        series: [
            {
                type: 'column',
                data: [],
                name: 'The number of Citations per each article is divided by the number of years since publication',
                color: ChartColors.greenOpacity,
                borderColor: ChartColors.green,
                borderWidth: 1,
                pointWidth: 8
            },
            {
                type: 'column',
                data: [],
                name: '[Leaders] The number of Citations per each article is divided by the number of years since publication',
                color: ChartColors.classicOrangeOpacity,
                borderColor: ChartColors.classicOrange,
                borderWidth: 1,
                pointWidth: 8
            },
        ]
    },

    'citation-contrib-rc-a-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first author)',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] Citations per year according to relative contribution (first author)',
                color: ChartColors.yellow,
                fillOpacity: ChartColors.yellowOpacityValue,
            },
        ]
    },

    'citation-contrib-rc-b-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first and last author)',
                color: ChartColors.blue,
                fillOpacity: ChartColors.blueOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] Citations per year according to relative contribution (first and last author)',
                color: ChartColors.orange,
                fillOpacity: ChartColors.orangeOpacityValue,
            },
        ]
    },

    'citation-contrib-journal-rc-a-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first author) + journal',
                color: ChartColors.green,
                fillOpacity: ChartColors.greenOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] Citations per year according to relative contribution (first author) + journal',
                color: ChartColors.classicOrange,
                fillOpacity: ChartColors.classicOrangeOpacityValue,
            },
        ]
    },
    'citation-contrib-journal-rc-b-chart': {
        title: {text: null},

        plotOptions: {
            line: {
                shadow: 'value'
            }
        },
        series: [
            {
                type: 'area',
                data: [],
                name: 'Citations per year according to relative contribution (first and last author) + journal',
                color: ChartColors.turquoiseBlue,
                fillOpacity: ChartColors.turquoiseOpacityValue,
            },
            {
                type: 'area',
                data: [],
                name: '[Leaders] Citations per year according to relative contribution (first and last author) + journal',
                color: ChartColors.yellow,
                fillOpacity: ChartColors.yellowOpacityValue,
            },
        ]
    },

};

export default ChartsConfig;