/**
 * Created by IntelliJ IDEA.
 * User: uzix
 * Date: 25/09/2018
 * Time: 10:24
 */

// function bubble_data_setup(chartData, store, article_year, stats) {
//     if (store.length === 0) {
//         //first time
//         store[0] = article_year;
//         store[1] = stats;
//     } else if (store[0] === article_year) {
//         store[1] += stats;
//     } else if (store[0] !== article_year) {
//         chartData.push([store[0], store[1], store[1]]);
//         store[0] = article_year;
//         store[1] = stats;
//     }
//
// }

// function true_false_data_setup(chartData, store, article_year, stats) {
//     if (store.length === 0) {
//         //first time
//         store[0] = article_year;
//         store[1] = stats;
//     } else if (store[0] === article_year && store[1] === 0) {
//         store[1] = stats;
//     } else if (store[0] !== article_year) {
//         chartData.push([store[0], store[1]]);
//         store[0] = article_year;
//         store[1] = stats;
//     }
//
// }

function cumulative_data_setup(chartData, store, article_year, stats, add_z_value) {
    if (store.length === 0) {
        //first time
        store[0] = article_year;
        store[1] = stats;
    } else if (store[0] === article_year) {
        store[1] += stats;
    } else if (store[0] !== article_year) {
        if (add_z_value){
            chartData.push([store[0], store[1], store[1]]);
        } else {
            chartData.push([store[0], store[1]]);
        }

        store[0] = article_year;
        store[1] = stats;
    }

}

function prepArticlesSeries(articles) {
    const chartData = {
        func1: [], func2: [], func3: [],
        func4: [],
        func5: [],
        func6: [],
        func6_store: [],
        func7: [],
        func7_store: [],
        func8: [],
        func9: [],

        func10: [],
        func10_store: [],
        func11: [],
        func12: [],
        func13: [],
        func14: [],

        func15: [],
    };

    for (let i in articles) {
        const article = articles[i];
        if (article['active'] === false || article.disabled) {
            continue
        }

        const stats = article['stats'];
        const article_year = article['year'];

        chartData['func1'].push([article_year, stats['sum_of_articles']]);
        chartData['func2'].push([article_year, stats['sum_of_relative_contribution_a']]);
        chartData['func3'].push([article_year, stats['sum_of_relative_contribution_b']]);

        chartData['func4'].push([article_year, stats['avg_articles_per_year']]);
        chartData['func5'].push([article_year, stats['authors_count']]);

        cumulative_data_setup(chartData['func6'], chartData['func6_store'], article_year, stats['sole_author'], true);
        cumulative_data_setup(chartData['func7'], chartData['func7_store'], article_year, stats['is_first_author'], true);
        // true_false_data_setup(chartData['func6'], chartData['func6_store'], article_year, stats['sole_author']);
        // true_false_data_setup(chartData['func7'], chartData['func7_store'], article_year, stats['is_first_author']);
        // chartData['func7'].push([article_year, stats['is_first_author']]);

        chartData['func8'].push([article_year, stats['sum_of_first_author']]);
        chartData['func15'].push([article_year, stats['sum_of_sole_author']]);
        chartData['func9'].push([article_year, stats['sum_of_citations']]);

        cumulative_data_setup(chartData['func10'], chartData['func10_store'], article_year, stats['sum_of_citations_year'], false);
        // chartData['func10'].push([article_year, stats['sum_of_citations_year']]);
        chartData['func11'].push([article_year, stats['sum_of_citations_contrib_a']]);
        chartData['func12'].push([article_year, stats['sum_of_citations_contrib_a_factor']]);
        chartData['func13'].push([article_year, stats['sum_of_citations_contrib_b']]);
        chartData['func14'].push([article_year, stats['sum_of_citations_contrib_b_factor']]);
    }
    return chartData;
}

function filterComparableArticles(articles) {
    const new_articles = articles.filter(article =>
                                         article['compare_index']
                                           // && parseInt(article['compare_index'], 10) > 0
                                           && article['active'] === true
                                           && !article.disabled);

    let normalized_index = 0;
    let last_article_index = new_articles[0]['compare_index'];

    return new_articles.map((article, index) => {
        if (article['compare_index'] > last_article_index) {
            normalized_index += 1;
        }

        console.log('>>> ', article['compare_index'], last_article_index, normalized_index);
        last_article_index = article['compare_index'];
        article['year'] = normalized_index;
        return article
    });
}

export function setupChartsData(articles, leaderArticles, charts, compareCharts) {
    const chartData = prepArticlesSeries(articles);
    const compareData = prepArticlesSeries(filterComparableArticles(articles));
    const leaderData = prepArticlesSeries(leaderArticles);

    // descriptive
    charts['avg-article-chart']['series'][0]['data'] = chartData['func4'];
    // charts['avg-article-chart']['series'][1]['data'] = leaderData['func4'];

    charts['author-count-chart']['series'][0]['data'] = chartData['func5'];
    // charts['author-count-chart']['series'][1]['data'] = leaderData['func5'];
    // charts['author-count-chart']['series'][2]['data'] = leaderData['func5'];

    charts['sole-author-chart']['series'][0]['data'] = chartData['func6'];
    // charts['sole-author-chart']['series'][1]['data'] = leaderData['func6'];

    charts['first-author-chart']['series'][0]['data'] = chartData['func7'];
    // charts['first-author-chart']['series'][1]['data'] = leaderData['func7'];

    charts['sum-first-author-chart']['series'][0]['data'] = chartData['func8'];
    // charts['sum-first-author-chart']['series'][1]['data'] = leaderData['func8'];

    charts['sum-sole-author-chart']['series'][0]['data'] = chartData['func15'];
    // charts['sum-sole-author-chart']['series'][1]['data'] = leaderData['func15'];

    charts['sum-citations-chart']['series'][0]['data'] = chartData['func9'];
    compareCharts['sum-citations-chart']['series'][0]['data'] = compareData['func9'];
    compareCharts['sum-citations-chart']['series'][1]['data'] = leaderData['func9'];

    // analysis

    // relative contribution
    charts['article-count-chart']['series'][0]['data'] = chartData['func1'];
    charts['article-count-chart']['series'][1]['data'] = chartData['func2'];
    charts['article-count-chart']['series'][2]['data'] = chartData['func3'];

    compareCharts['article-count-chart']['series'][0]['data'] = compareData['func1'];
    compareCharts['article-count-chart']['series'][1]['data'] = leaderData['func1'];

    compareCharts['article-count-rc-a-chart']['series'][0]['data'] = compareData['func2'];
    compareCharts['article-count-rc-a-chart']['series'][1]['data'] = leaderData['func2'];

    compareCharts['article-count-rc-b-chart']['series'][0]['data'] = compareData['func3'];
    compareCharts['article-count-rc-b-chart']['series'][1]['data'] = leaderData['func3'];

    charts['citation-per-year-chart']['series'][0]['data'] = chartData['func10'];
    // charts['citation-per-year-chart']['series'][1]['data'] = leaderData['func10'];
    compareCharts['citation-per-year-chart']['series'][0]['data'] = compareData['func10'];
    compareCharts['citation-per-year-chart']['series'][1]['data'] = leaderData['func10'];

    charts['citation-contrib-chart']['series'][0]['data'] = chartData['func11'];
    charts['citation-contrib-chart']['series'][1]['data'] = chartData['func13'];
    // charts['citation-contrib-chart']['series'][2]['data'] = leaderData['func11'];
    // charts['citation-contrib-chart']['series'][3]['data'] = leaderData['func13'];

    compareCharts['citation-contrib-rc-a-chart']['series'][0]['data'] = compareData['func11'];
    compareCharts['citation-contrib-rc-a-chart']['series'][1]['data'] = leaderData['func11'];
    compareCharts['citation-contrib-rc-b-chart']['series'][0]['data'] = compareData['func13'];
    compareCharts['citation-contrib-rc-b-chart']['series'][1]['data'] = leaderData['func13'];

    charts['citation-contrib-journal-chart']['series'][0]['data'] = chartData['func12'];
    charts['citation-contrib-journal-chart']['series'][1]['data'] = chartData['func14'];
    // charts['citation-contrib-journal-chart']['series'][2]['data'] = leaderData['func12'];
    // charts['citation-contrib-journal-chart']['series'][3]['data'] = leaderData['func14'];
    compareCharts['citation-contrib-journal-rc-a-chart']['series'][0]['data'] = compareData['func12'];
    compareCharts['citation-contrib-journal-rc-a-chart']['series'][1]['data'] = leaderData['func12'];

    compareCharts['citation-contrib-journal-rc-b-chart']['series'][0]['data'] = compareData['func14'];
    compareCharts['citation-contrib-journal-rc-b-chart']['series'][1]['data'] = leaderData['func14'];

    return charts
}
