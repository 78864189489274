<template>
    <div class="container home">

        <div class="siema carousel">
            <div class="slide blue mobile-padding">
                <div class="level is-mobile">
                    <div class="level-left h1">Problem</div>
                    <div class="level-right"><img src="../assets/blue-arrow.svg" class="arrow" @click="nextSlide"/></div>
                </div>

                <div class="columns level">
                    <div class="column level-left">
                        <p class="point">Developing Academic career is a multi-dimensional task, there are the personal, organizational and societal issues which evolve with opportunities and are primarily subjective.</p>
                        <p class="point indent">Is it necessarily right that a scholar with 10,000 citations is better than a one cited only 2000 times? Is it the final number which matters or the way of achieving it?</p>
                        <p class="point indent">People develop their research career in different ways, which need to be weighted in. Furthermore, it is essential to understand how a certain number was built up in time and how it is composed. </p>
                    </div>
                    <div class="column is-5 level-right">
                        <img src="../assets/problem.svg" />
                    </div>
                </div>
            </div>
            <div class="slide green mobile-padding">
                <div class="level is-mobile">
                    <div class="level-left h1">Solution</div>
                    <div class="level-right"><img src="../assets/green-arrow.svg" class="arrow" @click="nextSlide"/></div>
                </div>

                <div class="columns level">
                    <div class="column level-left">
                        <p class="point">My Academic Profile is a tool taking consideration of many parameters facilitating improvement in assessing research impact progress.</p>
                        <p class="point indent">We suggest ways of building ones’ Academic profile which may be used to analyze progress along the academic Career and for comparing achievements and impacts of researchers within the same field and between sub-fields within a faculty.</p>
                        <p class="point indent">We provide a system using open source information for allowing one to assess the profiles of himself with a comparison to profiles of others based on the same data and method.</p>
                    </div>
                    <div class="column is-5 level-right">
                        <img src="../assets/solution.svg" />
                    </div>
                </div>
            </div>
        </div>

        <div class="markers" v-bind:class="[carouselColor]">
            <div class="mark" v-bind:class="{active: currentSlide === 0}" @click="slideNumber(0)"></div>
            <div class="mark" v-bind:class="{active: currentSlide === 1}" @click="slideNumber(1)"></div>
        </div>

        <div class="charts mobile-padding">
            <div class="profile">
                <figure class="image is-square">
                    <img class="is-rounded" src="../assets/albert_einstein.jpg">
                </figure>
                <p class="body1">Example Profile</p>
                <p class="h1">Albert Einstein</p>
            </div>

            <div class="chart-list">
                <SingleChart
                        title="Average no. of Articles per year"
                        description="Accumulated no. Articles divided by the number of years. This metric represents the change in average productivity over the years."
                        color="blue"
                        :options="charts['avg-article-chart']"
                        ref="avg-article-chart"/>
                <SingleChart
                        title="No. of Authors"
                        description="The number of articles for each category of number of Authors. The distribution of articles according to the number of authors helps to differentiate between Collaborators and Individualists."
                        color="purple"
                        :options="charts['author-count-chart']"
                        ref="author-count-chart"/>
                <SingleChart
                        title="First Author"
                        description=""
                        color="purple"
                        :options="charts['first-author-chart']"
                        ref="first-author-chart"/>
                <SingleChart
                        title="Cumulative Sum of Citations"
                        description="This metric describes the progress in the total scientific impact."
                        color="blue"
                        className="last"
                        :options="charts['sum-citations-chart']"
                        ref="sum-citations-chart"/>
            </div>
        </div>


        <div class="left-right columns mobile-padding">
            <div class="left column"><h3>How we analyze and build an academic profile?</h3></div>
            <div class="right column">
                <p>High Relative Contribution (RC) may be gained from a few single-authored articles or many multiple-authored articles. The sum of RC reflects the work of Collaborators and Individualists similarly than the accumulated number of articles.</p>
                <p>Weighting the number of citations gained for each article according to RC similarly allows better comparison between researchers adopting different research and publication strategies.</p>
                <p><router-link :to="{name: 'About'}" class="has-underline">Read more</router-link> about the process.</p>
            </div>
        </div>

        <div class="left-right columns faq mobile-padding">
            <div class="left column">
                <h3>How to get an academic profile analysis?</h3>
                <router-link :to="{name: 'SignUp'}" class="button cta-button is-medium">Start Now FREE</router-link>
            </div>
            <div class="right column">
                <h2>{ Sign Up }</h2>
                <p>Sign up with Google account or by using an email address.</p>

                <h2>{ Upload CSV }</h2>
                <p>Upload CSV directly from Scopus with the list of published articles.</p>

                <h2>{ Get Results }</h2>
                <p>See an analyzed Academic Profile immediately.</p>

                <h2 class="premium">{ Get Full Report } PREMIUM</h2>
                <p>To access the full report and advanced metrics, <router-link :to="{name: 'FAQ'}" class="has-underline">Go Premium</router-link>.</p>
            </div>

        </div>
    </div>

</template>

<script>
    import Siema from 'siema';
    import SingleChart from '../components/SingleChart';
    import {setupChartsData} from '../charts/utils'
    import HP_ARTICLES from '../hp_stats'
    import ChartsConfig from '../charts/config';

    export default {
        name: 'HomePage',

        data: () => {
            return {
                manualTickOverride: false,
                carouselColor: 'blue',
                currentSlide: 0,
                charts: ChartsConfig
            }
        },

        mounted() {
            const that = this;

            const opt = {
                loop: true,
                duration: 500,
                onInit: () => {
                    that._slidesTimer = window.setInterval(() => {
                        if (!this.manualTickOverride){
                            this.siema.next();
                        }

                        this.manualTickOverride = false;
                    }, 6000);
                },
                onChange: () => {
                    this.carouselColor = this.siema.currentSlide === 0 ? 'blue' : 'green';
                    this.currentSlide = this.siema.currentSlide;
                }
            };
            this.siema = new Siema(opt);
            
            this.charts = setupChartsData(HP_ARTICLES, [], this.charts);
        },

        beforeDestroy() {
            if (this._slidesTimer !== null) {
                window.clearInterval(this._slidesTimer);
                this._slidesTimer = null;
                this.manualTickOverride = false;
            }
        },

        methods: {

            nextSlide() {
                this.manualTickOverride = true;
                this.siema.next();
            },

            slideNumber(num) {
                this.manualTickOverride = true;
                this.siema.goTo(num);
            }
            
        },
        components: {SingleChart}
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
