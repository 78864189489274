<template>
    <div class="tag-cloud">
        <b-taglist>
            <a :title="tag" href="#void" class="tag-wrapper is-capitalized" v-for="tag in tags" :key="tag" v-on:click="onTagClick(tag)">
                <b-tag size="is-large" :class="{active: selected[tag]}">{{tag}}</b-tag>
            </a>
        </b-taglist>
    </div>
</template>

<script>
    export default {
        name: 'tag-cloud',
        props: {
            tags: Array
        },

        data: () => {
            return {
                selected: {}
            }
        },

        mounted() {
            for (let i in this.tags) {
                this.selected[this.tags[i]] = false;
            }

            this.$forceUpdate();
        },

        methods: {
            onTagClick(tag) {
                const active = this.selected[tag];
                this.selected[tag] = !active;
                this.$forceUpdate();

                const _selected = [];
                for (let t in this.selected){
                    if (this.selected[t]){
                        _selected.push(t)
                    }
                }

                this.$emit('change', _selected);
            }
        }
    }
</script>
