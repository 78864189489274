<template>
    <div class="content map-content columns mobile-padding">
        <div class="column is-10 is-offset-1">
            <h1>Terms and Conditions</h1>
            <p>Welcome to My Academic Profile (the "MAP") web-based application owned and operated by Inspect Element Ltd. (the "Company" or "we"), which offers a unique platform allowing assessment and comparison of academic research impact process (the "Services").</p>

            <h3>General</h3>
            <ol>
                <li>These Terms of Service (the "Terms") govern your ("you", "your" or "user") use of MAP. We strongly recommend that prior to using our services you carefully read these Terms and any linked documents, including our <router-link :to="{name: 'Privacy'}" class="has-underline" title="Privacy Policy">Privacy Policy</router-link>.</li>
                <li>Please note that these Terms constitute a legally binding agreement between you and the Company, and if you do not agree with these Terms or any changes hereto, you may not use the services.</li>
                <li>We may modify these Terms from time to time. When we do, we will provide notice to you by publishing the most current version and revising the date at the top of this page and any modifications shall be effective immediately upon such publication. By continuing to use MAP after any changes come into effect, you are deemed to agree to the revised Terms.</li>
            </ol>

            <h3>Eligibility</h3>
            <ol>
                <li>Our Services are available to anyone who is at least 18 years old. By using MAP, you represent and warrant that you are at least eighteen (18) years old, or the applicable legal age of your place of residence and that you have the right, authority, and capacity to enter into these Terms and to abide by all the terms and conditions of these Terms.</li>
                <li>You may not use MAP if: (i) you are prohibited by local laws and regulations from using MAP; or (ii) you are not fully able and competent to enter into a binding contract with the Company.</li>
            </ol>

            <h3>The Services</h3>
            <ol>
                <li>My Academic Profile is a tool for evaluating an academic carrier based on varying parameters and in multiple dimensions (the “MAP Analysis”). The scope of the analysis included in the MAP Analysis may vary from time to time based on the information provided by the User and the type of Service purchased by the User.</li>
                <li>To get your academic profile and receive the full MAP Analysis, You need to provide MAP with a list of articles (with citation count, journal ISSN and publishing date) as well as the full name of the author(s) and any additional information requested by MAP at the time of access. In order to see your MAP compared to the leading scholars in that field, you will need to provide the author with the main field of research all as detailed in the registration page.</li>
            </ol>
            <p>Above notwithstanding MAP system can automatically retrieve information from various academic databases. In addition, the user may add information regarding publications not included in the automated retrieval process.</p>
            <ol>
                <li>After receiving such data, we will create your MAP Analysis that will visualize the quality and quantity of Your academic achievements, including in comparison to your peers.</li>
                <li>Your Map Analysis is private and available to you on your User pages, unless you confirm that the data provided related only to you personally and you specifically allow sharing.</li>
            </ol>

            <h3>Registration</h3>
            <ol>
                <li>In order to become a User, You must provide a valid email address, and choose a password. The Company reserves the right to require additional details in order to properly set up your User profile, including taking steps to verify your identity</li>
                <li>You are solely responsible for maintaining the confidentiality of your password and account and agree not to transfer your email address or password, lend or otherwise transfer your use of or access to MAP, to any third party.</li>
                <li>You are solely responsible for any and all activities that occur under your User account. You may change your password, or any other account information, at any time by following the instructions available on your account page.</li>
                <li>In order to protect the security of your account, we recommend that you log out of your account after using MAP through each registered device.</li>
                <li>You agree to notify us immediately on any unauthorized use of your account or any other breach of security related to your account or the platform.</li>
                <li>When you use the Services, you agree to: (i) provide certain current, complete, and accurate information about yourself when prompted to do so by MAP, and (ii) maintain and update this information as required to keep it current, complete and accurate. If any information provided by you during your original registration is inaccurate, then the Company reserves the right to terminate your User account immediately and to limit your access to the MAP.</li>
                <li>We are not liable for any loss or damage arising from your failure to comply with any of the foregoing obligations.</li>
            </ol>


            <h3>Intellectual Property</h3>
            <ol>
                <li>The Company hereby grants you, and you hereby accept a personal, non-transferable, non-exclusive license to MAP in order to utilize the Services, as they are provided to you by the Company, in the manner permitted by these Terms.</li>
                <li>The Company's name, logo and website design are owned by the Company and constitute the Company's intellectual property. All other trademarks appearing on MAP are trademarks of their respective owners. Our partners may also have additional proprietary rights in the content which they make available through the Services and MAP. The trade names, trademarks and service marks owned by us, whether registered or unregistered, may not be used in connection with any product or service that is not ours, in any manner that is likely to cause confusion. Nothing contained on MAP should be construed as granting, by implication, estoppels or otherwise, any license or right to use any of our trade names, trademarks or service marks without our express prior written consent.</li>
            </ol>

            <h3>User Conduct</h3>
            <ol>
                <li>
                    You hereby warrant and undertake that you shall not, nor encourage others or permit others to:
                    <ol>
                        <li>access or attempt to access MAP and/or data and/or materials by any means other than the interface provided by the Company, or use MAP in any manner that could damage, disable, overburden, or impair the Company's server or the networks connected to the Company's server;</li>
                        <li>attempt to violate, circumvent or breach any data security, authentication procedures, accounts, passwords, servers, networks measures, all which are employed by the Company or by any third party and/or use restrictions put into place by the Company to prevent certain uses of MAP;</li>
                        <li>violate any laws related to the access to or use of MAP and/or violate any requirements, procedures, policies, or regulations of networks connected to MAP and/or engage in any activity prohibited by these Terms;</li>
                        <li>impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                        <li>access MAP  while not logged onto the account and/or log into, or attempt to log into, a server or account which you are not authorized to access;</li>
                        <li>attempt to scan or test the vulnerability of the Company's servers, system or network and/or interfere with the website and/or platform and/or the Services, by any means including, without limitation, hacking the Company’s servers or systems, submitting a virus, overloading, mail-bombing or crashing;</li>
                        <li>use any automated methods or tools to crawl, robot, scrape, spider or otherwise monitor or extract data from any part of MAP without our express prior written permission (provided that we may use robot exclusion headers within MAP and you agree to comply with any such headers);</li>
                        <li>decipher, decompile, disassemble, reverse engineer or otherwise attempt to derive any of the source code or underlying ideas or algorithms of any part of MAP, except to the limited extent that applicable laws specifically prohibit such restrictions;</li>
                        <li>forge any TCP/IP packet header or any part of the header information in any email or posting, or in any way use the Services or any Content to send altered, deceptive or false source-identifying information;</li>
                        <li>reproduce, sell, trade, resell or exploit for any commercial purpose, MAP, access to the MAP and any of its metadata;</li>
                        <li>defraud, defame, or otherwise violate the legal rights (such as rights of privacy and publicity) of others;</li>
                        <li>mirror or frame any part of MAP on any other website;</li>
                        <li>use any meta-tags or other hidden text or metadata containing any of the Company's trademark, URL or product name without our express prior written permission;</li>
                        <li>use any of the Company's trademark, URL, product name, website design or logo in any way that incorrectly suggests our affiliation with or endorsement of any person, entity, product or service; or use any other trademarks, service marks, trade dress, designs or logos that are confusingly similar to any of the Company's trademark, product name or logo or to the look and feel of the Services;</li>
                        <li>remove, alter or tamper with any copyright, trademark or other proprietary notices or any digital watermarks or other technical measures used to indicate the source and/or ownership of any images or other Content;</li>
                        <li>assist or encourage any third party to do any of the above activities prohibited in this section or to otherwise violate any of the Company's Terms or policy;</li>
                        <li>engage in any other conduct which, in the Company’s sole discretion, is considered inappropriate, unauthorized or objectionable;</li>
                    </ol>
                </li>                
                <li>Without limitation to any other rights or remedies of the Company under these Terms, the Company reserves the right to investigate any situation that appears to involve any of the above, and may report such matters to, and cooperate with, appropriate law enforcement authorities in prosecuting any users who have participated in any such violations.</li>
                <li>In addition, you agree to comply with all applicable laws, regulations, and ordinances as a condition of use of the Services and/or MAP.</li>
                <li>In order to permit us to protect the quality of our Services, you hereby consent to our employees and representatives being able to access your account and records for any reason, in our sole discretion. We also reserve the right but do not assume the responsibility, to monitor or review your conduct while using the Services and platform.</li>
                <li>You agree to comply with the above conditions and acknowledge and agree that the Company has the right, in its sole discretion, to terminate your account or take such other action as we see fit if you breach any of the above conditions or any of the other terms of these Terms. This may include taking court action and/or reporting offending users to the relevant authorities.</li>
            </ol>


            <h3>Privacy Policy</h3>
            <ol>
                <li>The Company collects information, including Personal Information for the purpose of providing the Services.</li>
                <li>You hereby acknowledge and agree that the Company may disclose to third parties aggregate data derived from user data or from any other data related to the Services, so long as such data is not personally identifiable with respect to a user.</li>
                <li>The Company's protection of data and collection practices for data is reflected in the Company's <router-link :to="{name: 'Privacy'}" class="has-underline" title="Privacy Policy">Privacy Policy</router-link>. You agree to the Company's use of your data in accordance with the <router-link :to="{name: 'Privacy'}" class="has-underline" title="Privacy Policy">Privacy Policy</router-link>.</li>
            </ol>

            <h3>Liability</h3>
            <ol>
                <li>MAP analysis relies on existing databases and thus the Company does not make any representations as to the accuracy of the MAP Analysis. The Company shall not responsible for third party using the MAP Analysis for the purpose of evaluating a scholar, including for an academic position. The Company specifically disclaims any liability with regard to any actions resulting from User’s use of MAP, the reliance of the MAP Analysis, or arising from such, including any claim with respect to the accuracy of the Services, the result of the MAP Analysis any claim related thereto. The Company further states and the User acknowledges that the MAP analysis may be influenced by the availability of data in the databases used or the accuracy of the data provided by the User or the failure to provide such.</li>
                <li>The Company makes no warranties, express or implied, guarantees or conditions with respect to your use of the Services. You understand that use of the services is at your own risk and that we provide the services on an "As Is" basis and "As Available." You bear the entire risk of using the Services. The Company does not guarantee the accuracy or timeliness of the services. To the extent permitted under your local law, we exclude any implied warranties, including for fitness for a particular purpose and non-infringement. You acknowledge that computers are not fault-free and occasional periods of downtime occur. The Company does not guarantee the services will be uninterrupted, timely, secure, or error-free nor does the Company guarantee any connection to or transmission from the computer networks.</li>
                <li>To the fullest extent permitted by applicable law, you agree to indemnify and hold harmless the Company, its subsidiaries or affiliates and any officers, owners, directors, shareholders, consultants, agents and employees of the foregoing, from any indirect, incidental, exemplary, punitive, special or consequential demands or damages (actual and consequential) of every kind and nature, known or unknown, suspected and unsuspected, including reasonable attorneys’ fees, or any damages for loss of profits, revenue, data, use, goodwill or other intangible losses, arising out of or in any way connected with your use or inability to use the Services and/or platform, including but not limited to: (i) Your use or misuse of MAP; (ii) your reliance on the Services; or (iii) violation of these Terms.</li>
                <li>You expressly waive any benefit or protection, whether statutory or otherwise, that would limit the coverage of this release to include only those claims which you may know or suspect to exist in your favor at the time of agreeing to this release.</li>
                <li>In no event will the total liability of the Company and our officers, owners, directors, shareholders, consultants, agents and employees for any claim related to the Services exceed the lower of 100 EUR or the total amount paid by you to the Company in relation to the Services, if any, during the three (3) months before the act or omission giving rise to the claim.</li>
                <li>The limitations in this section apply to all claims, whether based on warranty, contract, tort (including negligence), strict liability or any other legal theory, whether or not we have been advised of the possibility of such damages, even if a remedy set forth in these Terms is found to have failed its essential purpose. Some jurisdictions do not allow the exclusion of consequential or incidental damages, so some of the exclusions in this section may not apply to you.</li>
                <li>The Company assumes no liability for any computer virus or similar code that is downloaded to a User’s device from MAP.</li>
                <li>The Company will not be liable for any loss that you may incur as a result of someone else using your password or account or account information in connection with MAP, either with or without your knowledge.</li>
            </ol>

            <h3>Availability of Service</h3>
            <ol>
                <li>We strive to keep the Services up and running; however, all online services suffer occasional disruptions and outages, and the Company is not liable for any disruption or loss you may suffer as a result.</li>
                <li>There will be occasions when access to MAP will be interrupted for maintenance, upgrades, and repairs that you acknowledge is a necessary function conducted by the Company or as a result of a failure of telecommunications links and equipment that are beyond the Company’s control. The Company shall bear neither responsibility nor liability for any harm that may result from such downtime.</li>
                <li>The Company may modify or discontinue, temporarily or permanently the Services, or any portion thereof. In such case, the Company will notify you in advance and provide you with an opportunity to access your account prior to discontinuation.</li>
                <li>The Company may, from time to time, release new tools and resources on MAP or introduce other services and/or features. Any new services and features will be subject to these Terms as well as any additional terms of use that we may release for those specific services or features.</li>
                <li>The Company may limit MAP’s availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion.</li>
            </ol>


            <h3>Links</h3>
            <ol>
                <li>MAP may include links to internet websites that are not operated by the Company. These links are meant for the User's convenience only, the Company has no control over these internet websites, and it is not responsible for the content contained on these websites. The inclusion of links to other websites does not attest to MAP’s support of the content of these websites nor any other connection to these websites or their operators. The Company is not responsible for the proper functioning of the links. The Company is entitled at its own discretion to remove any link from MAP and/or add additional links.</li>
                <li>The User may link to the MAP’s home page, provided the User does so in a way that is fair and legal and does not damage the Company’s reputation or take advantage of it, but the User must not establish a link in such a way as to suggest any form of association, approval or endorsement on the Company’s part where none exists. MAP must not be framed on any other website, nor may the User create a link to any part of MAP other than the home page. The Company reserves the right to withdraw linking permission without notice. The website from which the User is linking must comply in all respects with the content standards set out in these Terms.</li>
            </ol>

            <h3>Termination and Modification of the Services</h3>
            <ol>
                <li>You agree that we, in our sole discretion, may terminate your password, account (or any part thereof) or use of the Services, for any reason, including, without limitation, if we believe that you have violated or acted inconsistently with the letter or spirit of these Terms.</li>
                <li>We may also terminate your account upon receiving reliable information involving your violation of any law, and will cooperate with law enforcement agencies on such matters. We may also, in our sole discretion and at any time, discontinue providing the Services, or any part thereof, with or without notice.</li>
                <li>You agree that any termination of your access to MAP under any provision of these Terms may be effected without prior notice and acknowledge and agree that we may immediately deactivate or delete your account and all related information and files in your account, and/or bar any further access to such files or the Services. Further, you agree that we shall not be liable to you or any third party for any termination of your access to MAP.</li>
            </ol>

            <h3>Customer Support</h3>
            <ol>
                <li>You may contact the Company in connection with anything related to MAP and/or the Services at any time via our customer support, which is available at <i>info [at] myacademicprofile.com</i> or at “<router-link :to="{name: 'Contact'}" class="has-underline" title="Contact us page">Contact Us</router-link>” page on Our website.</li>
                <li>Any communication with the Company's customer support will be handled in the utmost care and without any delay by the Company's customer support representatives and will be escalated to the relevant people where necessary.</li>
            </ol>

            <h3>Notices</h3>
            <ol>
                <li>All notices to be given by the Company will be sent by email, to the email address provided by you to the Company during the registration process. You agree to monitor your email messages and/or MAP frequently to ensure awareness of any notice sent.</li>
                <li>Any notice to the Company will be given by contacting the Company by emailing us at <i>info [at] myacademicprofile.com</i>.</li>
                <li>The Company may broadcast, distribute or display notices or messages through MAP to inform you of changes to these Terms, the Services and MAP, the Privacy Policy or other matters of importance. Such broadcast, distributions or displays of information shall constitute notice to you.</li>
            </ol>

            <h3>Miscellaneous</h3>
            <ol>
                <li>These Terms shall be governed by, construed and interpreted in accordance with the laws of the State of Israel, without giving effect to any principles of conflicts of law.</li>
                <li>You agree that any action at law or in equity arising out of or relating to these Terms shall be filed only in courts located in Tel-Aviv and you hereby consent and irrevocably submit to the exclusive jurisdiction of such courts for the purposes of litigating any such action and you waive any jurisdictional, venue or inconvenient forum objections to such courts.</li>
                <li>You agree that any claim or cause of action arising out of your use of the Services and/or MAP or these Terms must be filed within 6 months after such claim or cause of action arose or it shall be deemed waived and forever be barred, notwithstanding any status of limitations or other law to the contrary. Within this period, any failure by the Company to enforce or exercise any provisions of these Terms or related right shall not constitute a waiver of that right or provision.</li>
                <li>No waiver by the Company of any breach or default hereunder will be deemed a waiver of any preceding or subsequent breach or default.</li>
                <li>If any provision of these Terms shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions.</li>
                <li>The Company may assign these Terms, in whole or in part, at any time with or without notice to you. You may not assign, transfer or sublicense these Terms or any or all of your rights or obligations under these Terms without the Company's express prior written consent.</li>
                <li>These Terms and the Company's <router-link :to="{name: 'Privacy'}" class="has-underline" title="Privacy Policy">Privacy Policy</router-link> are consisting the entire Terms between you and the Company relating to the subject matter herein and may not be modified by you. These Terms will inure to the benefit of the Company's successors, assigns and licensees.</li>
                <li>Without limitation, you agree that a printed version of these Terms and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</li>
            </ol>

            <p>Last updated: December 28th, 2018.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TermsPage',
        metaInfo: {
            title: 'Term and Conditions',
        },
    }
</script>
