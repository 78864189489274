<template>
    <div class="container columns signup search-page map-form">
        <div class="column is-6 is-offset-3">
            <h1>{2/3} Search Details</h1>
            <div class="sep"></div>

            <AuthorSearch :searchCallback="searchCallback"/>
        </div>
    </div>
</template>

<script>
    import AuthorSearch from '../components/AuthorSearch'

    export default {
        name: 'SearchPage',
        components: {AuthorSearch},

        data: () => {
            return {
            }
        },

        methods: {
            searchCallback(aid) {
                this.$toast.open(
                    {
                        message: 'Search submitted successfully',
                        type: 'is-success'
                    });

                window.setTimeout(() => {
                    this.$router.push({ name: 'Charts', params: { id: aid }})
                }, 2000);
            },
        }
    }
</script>

<style lang="scss">
    @import "../assets/scss/vars";

    .signup.search-page {
        h1 {
            display: inline-block;
            padding: 0 $space-2;
            background-color: $blue;
            color: white;
        }
    }
</style>