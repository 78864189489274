<template>
    <div class="content map-content columns mobile-padding">
        <div class="column is-10 is-offset-1">
            <h1>Privacy Policy</h1>

            <h3>General</h3>
            <ol>
                <li>This is the privacy policy ("Policy") of My Academic Profile, website [<a href="/" title="My Academic Profile homepage" target="_blank">https://www.myacademicprofile.com/</a>] (“MAP”) operated by Inspect Element Ltd. of David Shimoni st. Hertzelya, Israel (the “Company”, “We,” Us”, “Our”).</li>
                <li>The Company is committed to protecting your privacy. As part of this commitment, it is important for Us that you are aware of what information We (or others on Our behalf) collect about you, when We collect information about you, and how We use such information.</li>
                <li>This Privacy Policy is part of and should be read in conjunction with the <router-link :to="{name: 'Terms'}" class="has-underline" title="Terms and Conditions">Terms and Conditions</router-link>. When you access or use the website and/or Services, you agree that we (or others on our behalf) may collect, use and disclose your information (including Personal Information) in accordance with the terms of this Privacy Policy.</li>
            </ol>


            <h3>To what does this Policy apply?</h3>
            <ol>
                <li>This Policy describes the types of information that We collect about natural persons when We collect it, how We use it, share it with third parties, maintain it, etc.</li>
                <li>In this Policy, any reference to "Personal Data" is to any information relating to an identified or identifiable natural person; an identifiable natural person is one who can be identified, directly or in combination with additional information that We have or that We have access to.</li>
            </ol>


            <h3>When do We collect information about you?</h3>
            <ol>
                <li>
                    We may collect Personal Data about you in the following circumstances:
                    <ol>
                        <li>When you access or use MAP (the "Services");</li>
                        <li>When you interact with Us in any manner, including through Our customer's service.</li>
                    </ol>
                </li>
                <li>You are under no legal obligation to furnish any information about you, and the furnishing thereof is at your discretion and at your own free will. However, if you do not furnish certain information about you, We shall not be able to provide you with some of Our Services and/or products.</li>
            </ol>


            <h3>What types of information do We collect?</h3>
            <ol>
                <li>
                    We (or others on Our behalf) may collect the following types of Personal Data about you:
                    <ol>
                        <li>Registration information: upon registration to Our Services, you may provide and We may collect Personal Data from you your email address and password.</li>
                        <li>in order to use Our Services, you will have to provide us with certain information, including without limitation, your name, date of birth, email address, credit card or means of payment details, phone number and other contact information and information regarding your academic carrier, that may include information about your academic institute, research field, languages, honors, etc.</li>
                        <li>Communication information: when you contact us, including via MAP and/or social media networks, you may provide Us with your name, certain contact information (such as email address and telephone number) and the content of your communication with Us. Moreover, if you contact us telephonically, Our customer's service representatives may record and/or document in writing your calls.</li>
                        <li>Website usage information: this information includes online activity log, purchase history, traffic information including, without limitation, IP address, time of access, date of access, web and mobile page(s) visited, language used, software crash reports and type of browser used, information regarding the device you used and information collected by third-party services such as Google analytics and Facebook pixel.</li>
                        <li>If you subscribe to receive updates and/or newsletters from Us, you shall provide Us with your email address or other contact information.</li>
                        <li>Third Party Information: in order to provide the Service, we will retrieve information about You and your academic publications from third-party services.</li>
                    </ol>
                </li>
                <li>You are responsible for ensuring the accuracy of the information that you provide Us (or others on Our behalf) with. Inaccurate information will affect Our ability to provide you with Our Services and products, as well as Our ability to contact You as contemplated in this Policy. You may update your Personal Information by logging into your account and updating your personal information and/or by e-mailing customer support at info [at] myacademicprofile.com.</li>
            </ol>

            <h3>How do We use Personal Data?</h3>
            <p>We may use Personal Data about you for the following purposes:</p>
            <ol>
                <li>To operate and provide the Services;</li>
                <li>To personalize your experience when using the Services;</li>
                <li>To contact you for the purpose of operational requirements;</li>
                <li>To respond to your queries, requests and/or complaints, and to provide you with customer support services;</li>
                <li>To send you marketing materials if you agree to receive such publications;</li>
                <li>To analyze and improve Our products and services (including, but not limited to, the MAP), as well as to offer new ones;</li>
                <li>To analyze the effectiveness of any of Our marketing and advertising campaigns and activities;</li>
                <li>To perform and maintain various activities supporting the offering and provision of Our products and Services, including, but not limited to, MAP (such activities include back-office functions, business development activities, strategic decision making, financing management, etc.);</li>
                <li>To protect Our and third parties’ interests, rights and assets, including initiation or exercise or defense of legal claims;</li>
                <li>To fulfill Our legal or regulatory requirements.</li>
            </ol>


            <h3>Sharing of Personal Data</h3>
            <ol>
                <li>We will not disclose Personal Data about you to third parties except as detailed in this Policy.</li>
                <li>
                    We may share Personal Data about you with the following third parties or for the following purposes:
                    <ol>
                        <li>Upon your consent or instruction;</li>
                        <li>Third parties providing Us services related to the offering of Our Services and products (including MAP);</li>
                        <li>In the event that We sell, assign or transfer some or all of Our business or assets to a successor or acquirer, or if We are acquired by or merge with a third party, or if We file for bankruptcy or become insolvent, or any other situation where Personal Data may be sold, assigned or transferred to a successor or acquirer;</li>
                        <li>To enforce Our Terms of Use and other policies;</li>
                        <li>To protect Our rights, property, and interest or those of third parties;</li>
                        <li>To fulfill Our legal or regulatory requirements or to comply with a court order.</li>
                    </ol>
                </li>
            </ol>


            <h3>Transfer of Personal Data abroad</h3>
            <p>We may transfer Personal Data about you outside the jurisdiction which you are a resident of and store it in other jurisdictions and countries; the data protection and other laws of these jurisdictions or countries may not be as protective to Personal Data as those in the jurisdiction which you are resident – in these instances, We will take steps to ensure that an appropriate level of protection is given to your Personal Data.</p>


            <h3>Retention of Personal Data</h3>
            <p>We will retain Personal Data about you only for as long as necessary for the fulfillment of the purposes for which such Personal Data is collected. We may retain Personal Data about you for longer periods if We find it necessary to comply with legal requirements applicable to Us.</p>


            <h3>Choice / Opt-Out</h3>
            <ol>
                <li>If you will give your consent, We may provide you with communications from Us, as well as from third parties, either in connection with the Services or in connection with other products and services (including, but not limited to, commercial matters and offers). You hereby acknowledge and consent to the sending of such communications, and will not argue that such activity amounts to sending of unsolicited communications. You also acknowledge that We are not responsible for any communication sent to you by third parties, their content, and method of distribution.</li>
                <li>You may opt-out from receiving communications from Us by clicking on the unsubscribe link available in Our e-mails sent to you.</li>
            </ol>


            <h3>Cookies</h3>
            <ol>
                <li>We (or others on our behalf) use a browser feature known as cookies, which are small text files which are placed on your computer or equipment when you visit certain online or mobile pages, to track your activities, record your preferences, and make the Services more responsive to your needs by delivering a better and more personalized experience to you. The cookies are stored on your computer and/or equipment and used by us to help track your activity and pass information as you use the Services. Cookies can allow us to associate navigational information from browsing visits with personal information that you provide to us.</li>
                <li>You can change the privacy settings in the browser that you use, delete the existing cookies, prevent the creation of cookies or click the setting that enables you to accept or deny the creation of a cookie every time that a cookie is detected. If you opt to not accept cookies and/or cancel your acceptance of cookies, you will be limiting your ability to enjoy all of the interactive features on the website.</li>
            </ol>


            <h3>Security</h3>
            <p>We have implemented suitable security policies, rules and technical measures to protect and safeguard the Personal Data under Our control from unauthorized access, improper use or disclosure, unauthorized modification, unlawful destruction or accidental loss.  All of our employees and data processors that have access to, and are associated with the processing of your Personal Information, are obliged to respect the confidentiality of your Personal Information.</p>


            <h3>Disclaimer</h3>
            <p>The transmission of information via the internet is not completely secure. Although the Company does its best to protect your Personal Information, it cannot guarantee the security of the data transmitted to the website, mobile site and/or mobile application; transmission is at your own risk. Accordingly, the Company (including its directors, officers, shareholders, suppliers, advisors, contractors, and affiliates) will not be liable for any direct, indirect, incidental, consequential or punitive damages relating to the use or release of Personal Information resulting from events outside of the Company’s control.</p>


            <h3>Links to Other Websites</h3>
            <ol>
                <li>The Services may contain links to websites and/or applications of third parties. Other websites and applications may also reference or link to Our Services. We do not control such websites and applications, nor the collection and/or processing of Personal Data about you by such websites and applications, and thus We are not responsible for the privacy practices. This Policy does not apply to any actions taken via such websites and/or applications we assume no responsibility or liability whatsoever for the policies (including privacy policies), practices, actions or omissions of any such third parties.</li>
                <li>Whenever you access such third parties' websites and/or applications, We recommend that you carefully review their privacy policies prior to using such websites and/or applications and prior to disclosing any Personal Data about you.</li>
            </ol>


            <h3>Changes to the Policy</h3>
            <p>We may change this Policy in Our sole discretion at any time. Any revisions to this Policy will be posted as an updated version accessible via our website. We recommend that you revisit this Policy regularly to be kept apprised of any such changes.</p>

            <h3>Contact Us</h3>
            <p>For any queries relating to this Policy and data protection please contact Us at <i>info [at] myacademicprofile.com</i> or via the communication details appearing in the “<router-link :to="{name: 'Contact'}" class="has-underline" title="Contact us page">Contact Us</router-link>” section on Our website.</p>


            <p>Last updated: December 28th, 2018.</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PrivacyPage',
        metaInfo: {
            title: 'Privacy Policy',
        },
    }
</script>
