<template>
    <div :class="'chart' + (className ? ' ' + className : '')">
        <div class="chart-meta">
            <div class="level is-mobile">
                <div class="level-left">
                    <h2>{{ title }}</h2>
                </div>
                <!--<div class="level-right"><img src="../assets/info.svg"></div>-->
            </div>
        </div>
        <highcharts :options="options" ref="chart"></highcharts>
        <div class="chart-meta">
            <p>{{ description }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SingleChart',
        props: ['title', 'description', 'color', 'options', 'className'],
        methods: {
            chart() {
                return this.$refs['chart'].chart
            }
        }
    }
</script>
