<template>
    <footer class="footer" v-bind:class="[footerClass]">
        <div class="container">
            <!--<div class="level">-->
                <!--<div class="level-left">-->
                    <!--<router-link to="/" class="logo">-->
                        <!--<img src="../assets/logo-icon.svg" alt="" class="logo-icon">-->
                        <!--<img src="../assets/logo-type.svg" alt="" class="logo-type">-->
                    <!--</router-link>-->
                <!--</div>-->
                <!--<div class="level-right"><router-link :to="{name: 'Login'}" class="login" v-if="!isLoggedIn">LOGIN</router-link></div>-->
            <!--</div>-->

            <div class="level">
                <div class="level-left links">
                    <router-link :to="{name: 'About'}">About</router-link>
                    <router-link :to="{name: 'Terms'}">Terms</router-link>
                    <router-link :to="{name: 'Privacy'}">Privacy Policy</router-link>
                    <!--<a href="#">Pricing</a>-->
                    <router-link :to="{name: 'FAQ'}">FAQ</router-link>
                    <router-link :to="{name: 'Contact'}">Contact Us</router-link>
                </div>
                <div class="level-right rights">&copy; 2018 My Academic Profile ALL RIGHTS RESERVED.</div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'TheFooter',

        data: () => {
            return {
                isLoggedIn: false
            }
        },

        computed: {
            footerClass: function() {
                return this.$route.meta.footerHeader || '';
            }
        },

        mounted() {
            this.isLoggedIn = this.$mapSession.isloggedIn();
        },
    }
</script>

<style scoped>

</style>