<template>
    <div class="profiles mobile-padding">
        <div class="no-profiles" v-if="!isLoading && profiles.length === 0">
            <h2>No Profiles Yet</h2>
            <div class="h1">Get Started Now</div>
            <div>
                <b-icon icon="arrow-down" size="is-medium" class="arrow"></b-icon>
            </div>
            <router-link :to="{name: 'CreateProfile'}" class="button is-purple is-medium">Create New</router-link>
        </div>

        <div v-if="!isLoading && profiles.length > 0">
            <div class="level is-mobile">
                <div class="level-left">
                    <h1 class="level-item">Profiles</h1>
                </div>
                <div class="level-right">
                    <router-link :to="{name: 'CreateProfile'}" class="level-item button cta-button is-medium">Create New</router-link>
                </div>
            </div>
            <b-table :data="profiles" :hoverable="true" @click="profileSelected">

                <!--<template slot-scope="props" slot="header">-->
                    <!--<span v-if="props.column.field === 'years_range'">Number of Articles <span class="color-classic-grey">\ Active</span></span>-->
                    <!--<span v-else>{{ props.column.label }}</span>-->
                <!--</template>-->

                <template slot-scope="props">
                    <b-table-column field="first_name" label="First Name">
                        <span class="is-bold">{{ props.row.first_name }}</span>
                    </b-table-column>

                    <b-table-column field="last_name" label="Last Name">
                        <span class="is-bold">{{ props.row.last_name }}</span>
                    </b-table-column>

                    <b-table-column field="other_name" label="Other Name">
                        <span class="is-bold">{{ props.row.other_name }}</span>
                    </b-table-column>

                    <b-table-column field="categories" label="Category">
                        {{ props.row.categories[0] }}
                    </b-table-column>

                    <b-table-column field="years_range" label="Articles Years">
                        {{ props.row.years_range[0] }} - {{ props.row.years_range[1] }}
                    </b-table-column>

                    <b-table-column field="created_at" label="Created At">
                        {{ new Date(props.row.created_at*1000) | moment("MMMM Do YYYY, hh:mm:ss") }}
                    </b-table-column>

                    <b-table-column field="pid" label="" class="action">
                        <a href="#void"><b-icon icon="chevron-right"></b-icon></a>
                    </b-table-column>
                </template>
            </b-table>
        </div>

        <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
    </div>
</template>

<script>
    export default {
        name: 'ProfilesPage',
        metaInfo: {
            title: 'View your list of profiles',
        },

        data: () => {
            return {
                isLoading: false,
                profiles: [],
                columns: []
            }
        },

        mounted() {
            if (this.$mapSession.isloggedIn()) {
                this.isLoading = true;
                this.$http.get(this.$api.url('/profiles/list')).then(response => {
                    this.isLoading = false;

                    this.profiles = response.body.profiles

                }, response => {
                    this.handleServerError(response);
                }).catch(response => {
                    this.handleServerError(response);
                });
            } else {
                this.$router.push({name: 'Home'})
            }
        },

        methods: {
            showError(msg) {
                this.$toast.open({
                                     duration: 3000,
                                     message: msg,
                                     type: 'is-danger'
                                 })
            },

            handleServerError(response) {
                this.isLoading = false;
                if (response.status === 401) {
                    this.showError('Session expired, please login and try again');
                    this.$mapSession.clear();
                    this.$router.push({name: 'Login'})
                } else {
                    console.log(response);
                    this.showError('Error loading profiles, please try again or contact support');
                }
            },

            profileSelected(row) {
                this.$router.push({name: 'ProfileCharts', params: {id: row.pid}})
            }
        }
    }
</script>
