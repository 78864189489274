<template>
    <div class="settings" v-bind:class="{ active: isActive }">
        <div class="body column is-12-mobile">
            <div class="action"><a class="delete is-large" v-on:click="$emit('close')"></a></div>

            <div class="columns">
                <div class="column is-8-desktop is-offset-2-desktop is-10-mobile is-offset-1-mobile is-mobile">
                    <h2 class="h1">{{initials}} Search Settings</h2>
                    <div class="hr"></div>

                    <div class="import-export">
                        <h2 class="is-bold">Export/Import</h2>
                        <p class="body2">If some of the data is incorrect or missing, you can export the articles list, fix it and import it again.
                            The information you import will replace the current list of articles.</p>

                        <div class="field is-grouped">
                            <p class="control">
                                <a class="button" target="_blank" :href="this.$api.url('/articles/export?aid=' + this.aid)">
                                    <b-icon icon="export"></b-icon>
                                    <span>Export</span>
                                </a>
                            </p>
                            <p class="control">
                                <a class="button" @click="importDialog">
                                    <b-icon icon="import"></b-icon>
                                    <span>Inport</span>
                                </a>
                            </p>
                        </div>
                    </div>

                    <h2 class="is-bold is-marginless">Years</h2>
                    <p class="body2">Select years range</p>

                    <YearsRange
                            ref="yearsRange"
                            :min="this.yearsRange[0]"
                            :max="this.yearsRange[1]"
                            :initial-min="activeYearsRange['min']"
                            :initial-max="activeYearsRange['max']"
                            v-on:range-changed="yearsChanged"
                    />

                    <h2 class="is-bold">Articles List (<span>{{articles.length}}</span>)</h2>
                    <ul class="article-list">
                        <li class="media" v-bind:class="{disabled: article.disabled}" v-for="article in articles" v-bind:key="article.oid">
                            <div class="media-left">
                                <b-checkbox
                                        class="is-blue"
                                        size="is-medium"
                                        :disabled="article.disabled"
                                        v-model="article.active"
                                        v-on:input="() => {$emit('refresh', article)}"
                                ></b-checkbox>
                            </div>
                            <div class="media-content">
                                <p class="article-info">{{article.year}}, {{article.journal && article.journal.length > 0 ? article.journal[0] : article.source}}</p>
                                <p class="article-title body3">{{article.title}}</p>
                            </div>
                        </li>
                    </ul>

                </div>
                <!--<div class="column is-2-desktop is-1-mobile">-->
                <!--</div>-->
            </div>
        </div>
        <div class="blur column"></div>
        <b-modal :active.sync="isUploadModalActive" has-modal-card>
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Import Articles</p>
                </header>
                <section class="modal-card-body">

                    <b-upload v-model="files"
                              drag-drop>
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon
                                            icon="upload"
                                            size="is-large">
                                    </b-icon>
                                </p>
                                <p>Drop your file here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                    <div class="file-details body2" v-if="files && files.length">
                        Selected File:
                        <span class="file-name">
                            {{ files[0].name }}
                        </span>
                    </div>

                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="isUploadModalActive = false">Cancel</button>
                    <button class="button is-primary is-blue" @click="importArticles">Upload</button>
                </footer>
            </div>
        </b-modal>
        <b-loading :active.sync="isLoading"></b-loading>
    </div>
</template>

<script>
    import YearsRange from './form/YearsRange'

    export default {
        name: 'ChartsSettings',

        props: {
            visible: Boolean,
            initials: String,
            articles: Array,
            activeYearsRange: Object,
            aid: String
        },

        data: () => {
            return {
                isLoading: false,
                isUploadModalActive: false,
                files: []
            }
        },

        computed: {
            isActive: function () {
                return this.visible
            },

            yearsRange: function () {
                const ret = [10000, 0];

                this.articles.forEach((article) => {
                    const year = article.year;
                    ret[0] = Math.min(ret[0], year);
                    ret[1] = Math.max(ret[1], year);
                });

                return ret;
            }
        },

        mounted() {
        },

        methods: {
            showError(msg) {
                this.$toast.open({
                                     duration: 3000,
                                     message: msg,
                                     type: 'is-danger'
                                 })
            },

            yearsChanged(range) {
                this.$emit('range-changed', range);
            },

            importDialog() {
                this.files = [];
                this.isUploadModalActive = true;
            },

            importArticles() {
                if (this.files.length > 0 && this.files[0].name.endsWith('.csv')) {
                    this.isLoading = true;

                    this.$http.get(this.$api.url('/upload-token')).then(response => {
                        // {"url": "https://maprofile-uploads.s3.amazonaws.com/", "fields": {"key": "3wWVzYHwe8jDqYSyPRdT0MWNMgu0uUEx.csv", "AWSAccessKeyId": "ASIA4GQYHKN6ODXQFJMR", "x-amz-security-token": "FQoGZXIvYXdzED0aDEPGJQUYSnpivHgonSL0ASZmsy+NfsWYmUHpO9RuAdaIWP3kD3hbFTkjkZP4XydzoCnvuicSp8DJON014dra6Pzhwggcyin4ulXBhjKP5QfAZ9hyIwaODMSrjFt3RbfI9zfkKa4q482PYJ8YeaeoMYEO+1whGOvoZX5E2hj5WlQvthxN1V2A8X0DPSmFvONcNcQm2UdLWfh+/BUpUIl4LfeozCWxNpkmzuk8JvfQf9fQQVJIHr40Mo26N70Hc04Q068CzRBmcT/V7z7hBay6y1R1t105tGGzExQAHYsCw9DE4T59xVIQ7/6jqEpnM6fruRenzhlo6m2m2Z1jmGmCD9MLCOQo++HF2wU=", "policy": "eyJleHBpcmF0aW9uIjogIjIwMTgtMDgtMTNUMTE6NTc6MjhaIiwgImNvbmRpdGlvbnMiOiBbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDEwLCAxMDQ4NTc2XSwgeyJidWNrZXQiOiAibWFwcm9maWxlLXVwbG9hZHMifSwgeyJrZXkiOiAiM3dXVnpZSHdlOGpEcVlTeVBSZFQwTVdOTWd1MHVVRXguY3N2In0sIHsieC1hbXotc2VjdXJpdHktdG9rZW4iOiAiRlFvR1pYSXZZWGR6RUQwYURFUEdKUVVZU25waXZIZ29uU0wwQVNabXN5K05mc1dZbVVIcE85UnVBZGFJV1Aza0QzaGJGVGtqa1pQNFh5ZHpvQ252dWljU3A4REpPTjAxNGRyYTZQemh3Z2djeWluNHVsWEJoaktQNVFmQVo5aHlJd2FPRE1TcmpGdDNSYmZJOXpma0thNHE0ODJQWUo4WWVhZW9NWUVPKzF3aEdPdm9aWDVFMmhqNVdsUXZ0aHhOMVYyQThYMERQU21Gdk9OY05jUW0yVWRMV2ZoKy9CVXBVSWw0TGZlb3pDV3hOcGttenVrOEp2ZlFmOWZRUVZKSUhyNDBNbzI2TjcwSGMwNFEwNjhDelJCbWNUL1Y3ejdoQmF5NnkxUjF0MTA1dEdHekV4UUFIWXNDdzlERTRUNTl4VklRNy82anFFcG5NNmZydVJlbnpobG82bTJtMloxam1HbUNEOU1MQ09RbysrSEYyd1U9In1dfQ==", "signature": "ZZa8xTpBOUo7Dne9vyV9GLpvI2E="}, "token": "3wWVzYHwe8jDqYSyPRdT0MWNMgu0uUEx"}
                        const formData = new FormData();
                        const uploadToken = response.body.token;


                        for (let field in response.body.fields) {
                            formData.append(field, response.body.fields[field]);
                        }

                        formData.append('file', this.files[0], this.files[0].name);
                        this.$http.post(response.body.url, formData).then(response => {

                            const params = {
                                pid: this.aid,
                                token: uploadToken,
                            };

                            this.$http.post(this.$api.url('/articles/import'), params).then(response => {
                                this.isLoading = false;
                                this.isUploadModalActive = false;

                                this.$emit('set-articles', response.body.articles, response.body.years_range);
                                this.$emit('close');

                                this.$nextTick(function() {
                                    this.$refs.yearsRange.reset();
                                });

                                this.$toast.open({
                                                     duration: 3000,
                                                     message: 'Articles imported successfully',
                                                     type: 'is-success'
                                                 })

                            }, response => {
                                // error callback
                                this.isLoading = false;
                                console.log(response);
                                this.showError('Error saving file, please try again or contact support');
                            });

                        }, response => {
                            // error callback
                            this.isLoading = false;
                            console.log(response);
                            this.showError('Error uploading file, please try again or contact support');
                        });

                    }, response => {
                        // error callback
                        this.isLoading = false;
                        console.log(response);
                        this.showError('Error uploading file, please try again or contact support');
                    });

                } else {
                    this.showError('Please choose a CSV file to upload');
                }
            }
        },

        components: {YearsRange}
    }
</script>
