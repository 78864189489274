<template>
    <div id="app">
        <TheHeader v-if="showHeader"/>
        
        <section class="container site-content">
            <router-view></router-view>
        </section>

        <TheFooter v-if="showFooter"/>
    </div>
</template>
<script>
    import TheHeader from './components/TheHeader'
    import TheFooter from './components/TheFooter'

    export default {
        name: 'App',
        components: {TheHeader, TheFooter},
        metaInfo: {
            title: 'Analyze your academic career',
            titleTemplate: '%s | My Academic Profile'
        },

        computed: {
            showHeader() {
                return this.$route.meta.noHeader === undefined || !this.$route.meta.noHeader
            },

            showFooter() {
                return this.$route.meta.noFooter === undefined || !this.$route.meta.noFooter
            }
        },

        mounted(){
            if (document.getElementById('Cookiebot') === null) {
                let cookiebot = document.createElement('script');
                cookiebot.setAttribute('id', 'Cookiebot');
                cookiebot.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
                cookiebot.setAttribute('data-cbid', '5d9821c9-4cf2-499b-8258-94a276fc513a');
                cookiebot.setAttribute('async', 'async');
                document.head.appendChild(cookiebot)
            }
        }
    }

</script>
