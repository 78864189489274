'use strict';

import ChartColors from './colors'


export default function(Highcharts) {

    Highcharts.theme = {
        chart: {
            style: {
                fontFamily: 'Roboto, sans-serif'
            }
        },

        colors: [ChartColors.blue, ChartColors.blueOpacity,
            ChartColors.green, ChartColors.greenOpacity,
            ChartColors.turquoiseBlue, ChartColors.turquoiseOpacity,
            ChartColors.orange, ChartColors.orangeOpacity,
            ChartColors.classicOrange, ChartColors.classicOrangeOpacity,
            ChartColors.yellow, ChartColors.yellowOpacity],

        legend: {
            enabled: false,
        },

        tooltip: {
            valueDecimals: 4,
            shared: true,
            crosshairs: true,
            style: {
                color: ChartColors.darkGrey
            }
        },

        plotOptions: {
            series:{
                marker: {
                    symbol: 'circle'
                }
            },
            area: {
                fillOpacity: 0.15,
                lineWidth: 2,
                // stacking: 'normal'
            }
        },
        
        xAxis: {
            lineColor: ChartColors.lightGrey,
            lineWidth: 1,
            labels: {
                style: {
                    color: ChartColors.mediumGrey2,
                    fontSize: '16px',
                }
            },
            title: {
                text: null,
                // style: {
                //     color: $darkGrey,
                //     fontSize: '16px',
                // }
            }
        },

        yAxis: {
            lineColor: ChartColors.lightGrey,
            lineWidth: 1,
            labels: {
                style: {
                    color: ChartColors.mediumGrey2,
                    fontSize: '16px',
                }
            },
            title: {
                text: null,
                // style: {
                //     color: $darkGrey,
                //     fontSize: '16px',
                // }
            }
        },

        responsive: {
            rules: [{
                condition: {
                    maxWidth: 1024
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    chart: {
                        marginLeft: 0,
                        marginRight: 0,
                    },
                    xAxis: {
                        labels: {
                            style: {
                                fontSize: '12px',
                            }
                        }
                    },
                    yAxis: {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: 0,
                            style: {
                                fontSize: '12px',
                            }
                        },
                        title: {
                            text: ''
                        }
                    }
                }
            }]
        }

        // colorAxis: {
        //     maxColor: '#60042E',
        //     minColor: '#FDD089'
        // },
        //
        // plotOptions: {
        //     map: {
        //         nullColor: '#fefefc'
        //     }
        // },
        //
        // navigator: {
        //     series: {
        //         color: '#FF7F79',
        //         lineColor: '#A0446E'
        //     }
        // }
    };

    // Apply the theme
    Highcharts.setOptions(Highcharts.theme);
}