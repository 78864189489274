import Vue from 'vue'
import VueRouter from 'vue-router'
import VueResource from 'vue-resource';
import Buefy from 'buefy'

import App from './App.vue'
import HomePage from './pages/HomePage'
import SignUpPage from './pages/SignUpPage'
import SearchPage from './pages/SearchPage'
import ChartsPage from './pages/ChartsPage'
import FAQPage from './pages/FAQPage'
import ContactPage from './pages/ContactPage'
import ProfilesPage from './pages/ProfilesPage'
import CreateProfilePage from './pages/CreateProfilePage'
import AboutPage from './pages/AboutPage'
import PrivacyPage from './pages/PrivacyPage'
import TermsPage from './pages/TermsPage'
import SandboxPage from './pages/SandboxPage'

import MapSessions from './map_session'

import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts'
import HighchartsMore from 'highcharts/highcharts-more';
import indicators from 'highcharts/indicators/indicators';
// import linearregression from './charts/linearregression'
import theme from './charts/theme'
import GAuth from 'vue-google-oauth2'
import VueMeta from 'vue-meta'

Vue.use(Buefy, {defaultIconPack: 'mdi'});

HighchartsMore(Highcharts);
indicators(Highcharts);
// linearregression(Highcharts);
theme(Highcharts);
Vue.use(VueHighcharts, { Highcharts });

Vue.use(GAuth, {clientId: '709191113270-ckuqu9t6hn89ja5slaumoh5ilt456ft5.apps.googleusercontent.com', scope: 'profile email'});

Vue.use(VueRouter);
Vue.use(VueResource);
Vue.use(VueMeta);
Vue.use(require('vue-moment'));

require('./assets/scss/main.scss');
Vue.config.productionTip = false;

Vue.prototype.$api = {
    url: (path) => {
        return process.env.VUE_APP_BASE_API_URL + path;
    },
};

Vue.prototype.$mapSession = MapSessions;

// fix CORS cookie headers
Vue.http.interceptors.push((request, next) => {
    if (!(request.url.indexOf('.s3.amazonaws.com') > -1)){
        if (sessionStorage){
            const auth = sessionStorage.getItem('x-map-auth');
            if (auth){
                request.headers.set('X-Map-Auth', auth);
            }
        }
        request.credentials = true;
    }
    next();
});

Vue.prototype.$_ = require('lodash');

// Vue.prototype.$title_case = require('voca/title_case');

const routes = [
    {path: '/', name: 'Home', component: HomePage},
    {path: '/faq', name: 'FAQ', component: FAQPage},
    {path: '/signup', name: 'SignUp', component: SignUpPage, meta: {noHeader: true, noFooter: true}},
    {path: '/login', name: 'Login', component: SignUpPage, meta: {noHeader: true, noFooter: true}},
    {path: '/contact', name: 'Contact', component: ContactPage},
    {path: '/about', name: 'About', component: AboutPage},
    {path: '/privacy', name: 'Privacy', component: PrivacyPage},
    {path: '/terms', name: 'Terms', component: TermsPage},
    {path: '/search', name: 'Search', component: SearchPage},
    {path: '/profiles', name: 'Profiles', component: ProfilesPage},
    {path: '/create-profile', name: 'CreateProfile', component: CreateProfilePage, meta: {step: 1}},
    {path: '/create-profile/details', name: 'CreateProfile2', component: CreateProfilePage, meta: {step: 2}},
    { path: '/profile/:id', name: "ProfileCharts", component: ChartsPage, meta: {footerHeader: 'charts-footer'}},

    { path: '/__avg-tool', name: "Sandbox", component: SandboxPage},
];

const router = new VueRouter({
                                 mode: 'history',
                                 routes: routes,
                                 scrollBehavior (to, from, savedPosition) {
                                     return { x: 0, y: 0 }
                                 }
                             });


const vm = new Vue({
                       router,
                       el: '#app',
                       render: h => h(App),
                   });

document.addEventListener('DOMContentLoaded', function () {
    // vm.$mount('#app')
    window.__vm__ = vm
})
