<template>
    <div class="create-profile map-form columns mobile-padding">
        <div class="column is-6 is-offset-3">
            <transition :name="step === '1' ? 'slide-in-right' : 'slide-in-left'" mode="out-in">
                <div v-if="step === '1'" key="step-1">
                    <h1>{ 1/2 } Upload CSV</h1>
                    <div class="sep"></div>
                    <p class="body2">In order to analize an academic profile a lust of articles needs to be processed. Select your prefered method of uploading CSV</p>

                    <div class="box scopus">
                        <div class="body">
                            <div class="text">
                                <img src="../assets/scopus.png"/>
                                <p class="body2">Go to <a href="#">Scopus</a> search and download CSV for the Academic Profile you want to create</p>
                            </div>
                            <div class="upload-icon">
                                <b-field class="file">
                                    <b-upload
                                            v-model="scopusFiles"
                                            accept="text/csv"
                                            v-on:input="uploadCsv('scopus')"
                                    >
                                        <b-icon icon="upload" size="is-medium"></b-icon>
                                    </b-upload>
                                </b-field>
                            </div>
                        </div>
                        <div class="field" v-if="scopusFiles.length > 0">
                            <b-tag size="is-large" closable @close="removeFile('scopus')">
                                {{ scopusFiles[0].name }}
                            </b-tag>
                        </div>
                        <b-loading :is-full-page="false" :active.sync="uploading[0]"></b-loading>
                    </div>

                    <div class="sep-text">
                        <div class="sep"></div>
                        <div class="text">Or</div>
                        <div class="sep"></div>
                    </div>

                    <div class="box map">
                        <div class="body">
                            <div class="text">
                                <div class="v-align-center"><img src="../assets/logo-icon.svg"/> <span class="h2">Upload Our Template</span></div>
                                <p class="body2"><a href="#">Click here</a> to download our Excel template and upload it when your are done filling the CSV</p>
                            </div>
                            <div class="upload-icon">
                                <b-field class="file">
                                    <b-upload
                                            v-model="mapFiles"
                                            accept="text/csv"
                                            v-on:input="uploadCsv('map')"
                                    >
                                        <b-icon icon="upload" size="is-medium"></b-icon>
                                    </b-upload>
                                </b-field>
                            </div>
                        </div>
                        <div class="field" v-if="mapFiles.length > 0">
                            <b-tag size="is-large" closable @close="removeFile('map')">
                                {{ mapFiles[0].name }}
                            </b-tag>
                        </div>
                        <b-loading :is-full-page="false" :active.sync="uploading[1]"></b-loading>
                    </div>

                    <a href="#void" class="button cta-button is-medium is-fullwidth" :disabled="uploadToken === null" @click="nextStep">Next</a>
                    <router-link :to="{name: 'FAQ'}" class="has-underline">Learn More</router-link>
                </div>

                <div v-if="step === '2'" key="step-2" class="step-2 map-form">
                    <h1>{ 2/2 } Complete Profile</h1>
                    <div class="sep"></div>
                    <h3 class="body3">Add author name as it appears in publications</h3>

                    <section class="author-name">
                        <b-field
                                label="First Name"
                                :type="firstName.isError ? 'is-danger' : ''"
                                :message="firstName.isError ? firstName.message : ''">
                            <b-input
                                    size="is-large"
                                    placeholder="First Name"
                                    v-model="firstName.value"
                                    v-on:input="fieldChanged"
                                    :aria-invalid="firstName.isError"
                                    required></b-input>
                        </b-field>
                        <b-field label="Other Name">
                            <b-input size="is-large" placeholder="Other Name" v-model="otherName"></b-input>
                        </b-field>
                        <b-field
                                label="Last Name"
                                :type="lastName.isError ? 'is-danger' : ''"
                                :message="lastName.isError ? lastName.message : ''">
                            <b-input
                                    size="is-large"
                                    placeholder="Last Name"
                                    v-model="lastName.value"
                                    v-on:input="fieldChanged"
                                    :aria-invalid="lastName.isError"
                                    required></b-input>
                        </b-field>
                    </section>

                    <div class="sep"></div>

                    <h3 class="body3" id="tag_cloud_label">Add author’s academic categories</h3>
                    <TagCloud :tags="all_categories" @change="tagsChanged" aria-labelledby="tag_cloud_label"></TagCloud>
                    <div class="cats-error help" v-if="isCategoriesError">Please select at least one category</div>

                    <a href="#void" class="button cta-button is-medium is-fullwidth" @click="buildProfile">Build Profile</a>
                    <a href="#void" class="has-underline" @click="step = '1'">Back</a>
                    <b-loading :is-full-page="true" :active.sync="step2Loading"></b-loading>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
    import TagCloud from '../components/form/TagCloud'
    import CATEGORIES from '../categories'

    export default {
        name: 'CreateProfilePage',
        metaInfo: {
            title: 'Create new profile by upload csv of your articles',
        },

        data: () => {
            return {
                step: '1',
                uploading: [false, false],
                scopusFiles: [],
                mapFiles: [],
                uploadToken: null,
                lastUpload: null,

                // step 2 props
                step2Loading: false,
                firstName: {
                    value: '',
                    message: 'Please type in first name',
                    isError: false,
                },
                lastName: {
                    value: '',
                    message: 'Please type in last name',
                    isError: false,
                },
                otherName: '',
                isCategoriesError: false,
                selectedCategories: [],
                submitClicked: false,
                all_categories: CATEGORIES,
            }
        },

        computed: {
            // all_categories() {
            //     const cats = [];
            //     for (let c in CATEGORIES) {
            //         cats.push(c)
            //     }
            //
            //     return cats;
            // }
        },

        methods: {
            nextStep(){
                if (this.uploadToken !== null) {
                    this.step = '2';
                }
            },

            showError(msg) {
                this.$toast.open({
                                     duration: 3000,
                                     message: msg,
                                     type: 'is-danger'
                                 })
            },

            uploadError(uploadingSlot, response) {
                this.$set(this.uploading, uploadingSlot, false);

                if (response.status === 401) {
                    this.showError('Session expired, please login and try again');
                    this.$mapSession.clear();
                    this.$router.push({name: 'Login'})
                } else {
                    console.log(response);
                    this.showError('Error uploading file, please try again or contact support');
                }
            },

            removeFile(type) {
                if (type === 'scopus') {
                    this.scopusFiles = [];
                } else {
                    this.mapFiles = [];
                }

                if (this.lastUpload === type) {
                    this.uploadToken = null;
                    this.lastUpload = null;
                }
            },

            uploadCsv(type) {
                const uploadingSlot = type === 'scopus' ? 0 : 1;
                this.uploading[uploadingSlot] = true;

                this.$http.get(this.$api.url('/upload-token')).then(response => {
                    // {"url": "https://maprofile-uploads.s3.amazonaws.com/", "fields": {"key": "3wWVzYHwe8jDqYSyPRdT0MWNMgu0uUEx.csv", "AWSAccessKeyId": "ASIA4GQYHKN6ODXQFJMR", "x-amz-security-token": "FQoGZXIvYXdzED0aDEPGJQUYSnpivHgonSL0ASZmsy+NfsWYmUHpO9RuAdaIWP3kD3hbFTkjkZP4XydzoCnvuicSp8DJON014dra6Pzhwggcyin4ulXBhjKP5QfAZ9hyIwaODMSrjFt3RbfI9zfkKa4q482PYJ8YeaeoMYEO+1whGOvoZX5E2hj5WlQvthxN1V2A8X0DPSmFvONcNcQm2UdLWfh+/BUpUIl4LfeozCWxNpkmzuk8JvfQf9fQQVJIHr40Mo26N70Hc04Q068CzRBmcT/V7z7hBay6y1R1t105tGGzExQAHYsCw9DE4T59xVIQ7/6jqEpnM6fruRenzhlo6m2m2Z1jmGmCD9MLCOQo++HF2wU=", "policy": "eyJleHBpcmF0aW9uIjogIjIwMTgtMDgtMTNUMTE6NTc6MjhaIiwgImNvbmRpdGlvbnMiOiBbWyJjb250ZW50LWxlbmd0aC1yYW5nZSIsIDEwLCAxMDQ4NTc2XSwgeyJidWNrZXQiOiAibWFwcm9maWxlLXVwbG9hZHMifSwgeyJrZXkiOiAiM3dXVnpZSHdlOGpEcVlTeVBSZFQwTVdOTWd1MHVVRXguY3N2In0sIHsieC1hbXotc2VjdXJpdHktdG9rZW4iOiAiRlFvR1pYSXZZWGR6RUQwYURFUEdKUVVZU25waXZIZ29uU0wwQVNabXN5K05mc1dZbVVIcE85UnVBZGFJV1Aza0QzaGJGVGtqa1pQNFh5ZHpvQ252dWljU3A4REpPTjAxNGRyYTZQemh3Z2djeWluNHVsWEJoaktQNVFmQVo5aHlJd2FPRE1TcmpGdDNSYmZJOXpma0thNHE0ODJQWUo4WWVhZW9NWUVPKzF3aEdPdm9aWDVFMmhqNVdsUXZ0aHhOMVYyQThYMERQU21Gdk9OY05jUW0yVWRMV2ZoKy9CVXBVSWw0TGZlb3pDV3hOcGttenVrOEp2ZlFmOWZRUVZKSUhyNDBNbzI2TjcwSGMwNFEwNjhDelJCbWNUL1Y3ejdoQmF5NnkxUjF0MTA1dEdHekV4UUFIWXNDdzlERTRUNTl4VklRNy82anFFcG5NNmZydVJlbnpobG82bTJtMloxam1HbUNEOU1MQ09RbysrSEYyd1U9In1dfQ==", "signature": "ZZa8xTpBOUo7Dne9vyV9GLpvI2E="}, "token": "3wWVzYHwe8jDqYSyPRdT0MWNMgu0uUEx"}
                    const formData = new FormData();
                    const uploadToken = response.body.token;
                    const files = type === 'scopus' ? this.scopusFiles : this.mapFiles;


                    for (let field in response.body.fields) {
                        formData.append(field, response.body.fields[field]);
                    }

                    formData.append('file', files[0], files[0].name);
                    this.$http.post(response.body.url, formData).then(response => {

                        const params = {
                            token: uploadToken,
                        };

                        this.$http.post(this.$api.url('/profiles/upload-csv'), params).then(response => {
                            this.$set(this.uploading, uploadingSlot, false);
                            this.uploadToken = uploadToken;
                            this.lastUpload = type;

                        }, response => {
                            // error callback
                            this.uploadError(uploadingSlot, response);
                        }).catch(reason => {
                            this.uploadError(uploadingSlot, reason)
                        });

                    }, response => {
                        // error callback
                        this.uploadError(uploadingSlot, response);
                    }).catch(reason => {
                        this.uploadError(uploadingSlot, reason)
                    });

                }, response => {
                    // error callback
                    this.uploadError(uploadingSlot, response);
                }).catch(reason => {
                    this.uploadError(uploadingSlot, reason)
                });
            },

            // step 2 methods

            fieldChanged() {
                if (this.submitClicked){
                    this.validateForm();
                }
            },

            tagsChanged(selected) {
                this.selectedCategories = selected;
                if (this.submitClicked){
                    this.validateForm();
                }
            },

            validateForm() {
                let error = false;
                this.firstName.isError = false;
                this.lastName.isError = false;
                this.isCategoriesError = false;

                if (this.firstName.value === '') {
                    this.firstName.isError = true;
                    error = true;
                }

                if (this.lastName.value === '') {
                    this.lastName.isError = true;
                    error = true;
                }

                if (this.selectedCategories.length === 0){
                    this.isCategoriesError = true;
                    error = true;
                }

                return error;
            },

            buildProfile() {
                this.submitClicked = true;

                if (!this.validateForm()) {
                    this.step2Loading = true;
                    const params = {
                        token: this.uploadToken,
                        upload_source: this.lastUpload,
                        first_name: this.firstName.value,
                        last_name: this.lastName.value,
                        other_name: this.otherName,
                        categories: this.selectedCategories,
                    };
                    this.$http.post(this.$api.url('/profiles/create'), params).then(response => {
                        this.step2Loading = false;
                        console.log(response);
                        this.$router.push({name: 'ProfileCharts', params: {id: response.body.pid}})
                        
                    }, response => {
                        // error callback
                        console.log(response);
                        this.step2Loading = false;
                    }).catch(reason => {
                        this.step2Loading = false;
                        console.log(reason);
                    });
                }
            },
        },

        components: {TagCloud}
    }
</script>
