<template>
    <div class="container columns faq-page mobile-padding">
        <div class="column is-6 is-offset-3">
            <h1>FAQ</h1>
            <dl class="faq">
                <dt v-bind:class="{ 'is-open': faqs[0] }">
                    <a :aria-expanded="faqs[0]" aria-controls="faq0" class="h2" v-on:click="showHide(0)">
                        What is My Academic Profile?
                    </a>
                </dt>
                <dd id="faq0" v-bind:class="{ 'is-open': faqs[0] }">
                    <p>My Academic Profile is a tool which takes many parameters into consideration when assessing an academic career path, including research, impact, and progress. Time elapsed since an article’s publication, the number of authors, the position of an author in the list of contributors, the journal impact factor, and the seniority of co-authors are among the parameters influencing the determination of an author’s impact. At My Academic Profile, we account for each when determining a professional’s career path.</p>
                    <p>We suggest ways of incorporating these considerations to enhance or build ones’ academic profile. These considerations and other career milestones may be used to analyze the progress of an academic career. Additionally, My Academic Profile can compare the achievements and impacts of researchers within the same field as well as between sub-fields within a faculty.</p>
                    <p>My Academic Profile is powered by using open source information, which allows one to assess and compare their profiles to others based on the same data and methods.</p>
                </dd>

                <dt v-bind:class="{ 'is-open': faqs[1] }">
                    <a :aria-expanded="faqs[1]" aria-controls="faq1" class="h2" v-on:click="showHide(1)">
                        Why do I need an academic profile?
                    </a>
                </dt>
                <dd id="faq1" v-bind:class="{ 'is-open': faqs[1] }">
                    <p>Developing Academic career is a multi-dimensional task. There are personal, organizational and societal issues which evolve with opportunities and are primarily subjective.</p>
                    <p>My Academic Profile provides users with a comparison tool to assess career achievements. It is a tool for individual developmental evaluation and planning. Additionally,  thanks to its objectivity, My Academic Profile is also a tool for promotion committees as a specific, essential part of their evaluation process.</p>
                </dd>

                <dt v-bind:class="{ 'is-open': faqs[2] }">
                    <a :aria-expanded="faqs[2]" aria-controls="faq2" class="h2" v-on:click="showHide(2)">
                        What kind of information will I receive?
                    </a>
                </dt>
                <dd id="faq2" v-bind:class="{ 'is-open': faqs[2] }">
                    <p>My Academic Profile provides users with a series of descriptive measurements to track progress. They include:</p>
                    <p><u>ACCU-AR-SUM</u>: Accumulated Sum of Articles from the beginning of the academic career. A simple metric representing the total number of articles each year, including an express change in publication rates.</p>
                    <p><u>Single-AU-AR</u>: Number of Single Authored articles per year. Single authored articles best identify the author’s contribution. There are disciplines requiring a maximum number of such articles. Meanwhile, others view articles written with supervised students as of equal importance.</p>
                    <p><u>ACCU-SingleAU</u>: Accumulated Sum of single Authored Articles from the beginning of the Academic Career. The total number of single-authored articles each year represents the author’s own individual productivity and its rate of change over time.</p>
                    <p><u>No-of-AU</u>: Number of articles for each category by number of Authors. The distribution of articles according to the number of authors helps differentiate between Collaborators and Individualists.</p>
                    <p><u>AVG-#-AR/Y</u>: Accumulated number of articles divided by the number of years. This metric represents the change in average productivity over the years.</p>
                    <p><u>No-of Citations-Per-Pub-Year</u>: Sum of citations for articles published at each year. This metric helps identify the years and periods where high impact research and trends of change were produced, increasing scientific impact.</p>
                    <p><u>ACCU-No-of Citations</u>: Cumulative Sum of citations. This metric describes the progress concerning its total scientific impact.</p>

                    <p>Additionally, My Academic Profile includes a series of Analysis Measurements:</p>
                    <p><u>ACCU-RC-SUM</u>: Accumulated Sum of Relative Contribution for Articles from the beginning of the Academic Career. A metric representing the total production achieved up to each year as measured by each article and the author’s Relative Contribution (RC). This metric allows for comparison between researchers adopting different research and publication strategies.</p>
                    <p><u>ACCU-RC-X-CIT</u>: Accumulated Sum of Citations weighted by Relative Contribution for each cited article. A metric representing the progress of total impact following its weighting by RC. This metric allows for comparisons between the impacts of researchers adopting different research and publication strategies.</p>
                    <p><u>CitPerY</u>: The total number of citations for each article increases over the years. The average number of citations per year allows for better comparison between articles just published and articles earlier published.</p>
                    <p><u>ACCU- CitPerY-AR</u>: Accumulated Sum of Citations weighted by CitPerY. This allows for a better comparison between junior and senior researchers.</p>
                    <p><u>NJIF</u>: Normalized Journal Impact Factor. When quantifying the academic impact, citations of articles in journals of high impact should receive a more significant weight than those articles published in moderate and low impact journals. For this purpose, it is necessary to normalize the Journal Impact Factors differently for each research area.</p>
                    <p><u>ACCU-X-NJIF</u>: Accumulated Sum of Citations weighted by CitPerY and NJIF. Such parameterization facilitates differentiation between a researchers’ impact according to a journal’s quality and allows for comparison between researchers in different fields.</p>
                </dd>

                <dt v-bind:class="{ 'is-open': faqs[3] }">
                    <a :aria-expanded="faqs[3]" aria-controls="faq3" class="h2" v-on:click="showHide(3)">
                        How Do I Get an Academic Profile?
                    </a>
                </dt>
                <dd id="faq3" v-bind:class="{ 'is-open': faqs[3] }">
                    <p>To get an academic profile and access all measurement metrics, you need to provide a list of articles, with citation count, journal ISSN and publishing date, as well as the full name of the author. To compare with leaders in the field, you will need to provide the author with the main field of research.</p>
                    <p>We support two different formats: Scoupos and My Academic Profile minimal format. To complete this step, download your information from Scoupos’ website (https://www.scopus.com/) or download the My Academic Profile’s minimal template by clicking here.</p>
                    <p>To upload the information, just provide us with a CSV file using one of the two methods listed above.</p>
                </dd>

                <dt v-bind:class="{ 'is-open': faqs[4] }">
                    <a :aria-expanded="faqs[4]" aria-controls="faq4" class="h2" v-on:click="showHide(4)">
                        Can I share my own academic profile?
                    </a>
                </dt>
                <dd id="faq4" v-bind:class="{ 'is-open': faqs[4] }">
                    <p>Yes, you can share your own academic profile, and only your profile, through our sharing settings.</p>
                </dd>

                <dt v-bind:class="{ 'is-open': faqs[5] }">
                    <a :aria-expanded="faqs[5]" aria-controls="what-is-rc" class="h2" v-on:click="showHide(5)">
                        What is Relative Contribution?
                    </a>
                </dt>
                <dd id="what-is-rc" v-bind:class="{ 'is-open': faqs[5] }" data-index="5">
                    <p>For an article authored by several authors, it is essential to define the author's contribution or importance between the team of authors.</p>
                    <p>There are several options for determining their contribution:</p>
                    <p><u>First Author versus Other Authors:</u><br/>
                        The relative contribution of the First Author decreases as a function of the number of authors (<i>1/SQRT(Number of Authors + 1)</i>). The other authors share the remainder contribution.</p>
                    <p><img src="../assets/first-author-only-rc.jpg" alt="first author only relative contribution graph"/> </p>

                    <p><u>First Author, Last Author & Other Authors:</u><br/>
                        The relative contribution of the First Author decreases as a function of the number of authors (<i>1/SQRT(Number of Authors + 1)</i>). Other authors share the remainder of the contribution. The last Author gets a contribution twice the amount of the other authors. In this way, we increase the number of authors to allow the last author to get a double weight.
                    </p>
                    <p><img src="../assets/first-last-authors-rc.jpg" alt="first and last authors relative contribution graph"/> </p>

                </dd>
            </dl>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FAQPage',
        metaInfo: {
            title: 'Answers, questions and explanations',
        },

        data: () => {
            return {
                faqs: [false, false, false, false, false, false]
            }
        },

        methods: {
            showHide(pos) {
                this.$set(this.faqs, pos, !this.faqs[pos]);
            }
        },

        mounted() {
            if (this.$route.hash !== '') {
                const hash = this.$route.hash.replace('#', '');
                const elem = document.getElementById(hash);
                this.showHide(parseInt(elem.getAttribute('data-index'), 10));

                setTimeout(()=> {
                    elem.previousElementSibling.scrollIntoView(true);
                }, 1000);
            }
        }
    }
</script>
