<template>
    <div class="header-top">
        <div class="container">
            <nav class="navbar" role="navigation" aria-label="main navigation">
                <div class="navbar-brand">
                    <div class="navbar-item logo">
                        <router-link to="/"><img src="../assets/logo-icon.svg" alt="My Academic Profile logo (x-y graph)" class="logo-icon"></router-link>
                        <router-link to="/"><img src="../assets/logo-type.svg" alt="MyAcademic.profile" class="logo-type"></router-link>
                    </div>

                    <a role="button" class="navbar-burger burger"
                       aria-label="menu" aria-expanded="false"
                       data-target="navbarBurger"
                       v-bind:class="{'is-active': isMenuActive}"
                       @click="isMenuActive = !isMenuActive">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div id="navbarBurger" class="navbar-menu" v-bind:class="{'is-active': isMenuActive}">
                    <div class="navbar-end">
                        <div class="navbar-item" v-if="isLoggedIn">
                            <router-link :to="{name: 'Profiles'}" title="see list of profiles" class="link">Profiles</router-link>
                            <!--<router-link :to="{name: 'Login'}" class="link">Settings</router-link>-->
                            <a class="link" title="logout" @click="logout">Logout</a>
                        </div>
                        <div class="navbar-item" v-else>
                            <router-link :to="{name: 'Login'}" title="Login" class="button is-medium is-white">LOGIN</router-link>
                            <router-link :to="{name: 'SignUp'}" title="register and start now" class="button is-medium is-purple">Start Now</router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TheHeader',

        data: () => {
            return {
                isLoggedIn: false,
                isMenuActive: false
            }
        },

        mounted() {
            this.isLoggedIn = this.$mapSession.isloggedIn();
        },

        methods: {
            logout() {
                this.$http.post(this.$api.url('/logout')).then(response => {

                    this.$mapSession.clear();
                    window.location.href = '/';

                }, response => {
                    // error callback
                    console.log(response);
                    this.$toast.open({
                                         duration: 4000,
                                         message: `Oops Error just happened, please try again or contact support`,
                                         type: 'is-danger'
                                     })
                });
            }
        }
    }
</script>

<style scoped>

</style>