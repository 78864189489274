const categories_tree = {
 "multidisciplinary": [
  "Media Technology",
  "Multidisciplinary",
  "Nature and Landscape Conservation",
  "Waste Management and Disposal"
 ],
 "social sciences": [
  "Accounting",
  "Anthropology",
  "Archaeology",
  "Cognitive Neuroscience",
  "Communication",
  "Community and Home Care",
  "Decision Sciences (miscellaneous)",
  "Economics",
  "Economics and Econometrics",
  "Finance",
  "Forestry",
  "General Economics",
  "General Social Sciences",
  "Language and Linguistics",
  "Linguistics and Language",
  "Logic",
  "Phychiatric Mental Health",
  "Sensory Systems",
  "Social Sciences (miscellaneous)",
  "Tourism"
 ],
 "business & management": [
  "Business",
  "Business and International Management",
  "Forestry",
  "Fundamentals and skills",
  "General Business",
  "General Decision Sciences",
  "Human Factors and Ergonomics",
  "Industrial relations",
  "Information Systems and Management",
  "Leadership and Management",
  "Management",
  "Management Information Systems",
  "Management Science and Operations Research",
  "Management of Technology and Innovation",
  "Marketing",
  "Organizational Behavior and Human Resource Management",
  "Public Administration",
  "Strategy and Management",
  "Transportation"
 ],
 "other": [
  "Analysis",
  "Assessment and Diagnosis",
  "Control and Optimization",
  "Development",
  "Filtration and Separation",
  "Gender Studies",
  "Instrumentation",
  "Issues",
  "Modelling and Simulation",
  "Research and Theory",
  "Review and Exam Preparation",
  "Safety",
  "Safety Research",
  "Urban Studies"
 ],
 "computer science": [
  "Artificial Intelligence",
  "Communication",
  "Computational Mechanics",
  "Computational Theory and Mathematics",
  "Computer Graphics and Computer-Aided Design",
  "Computer Networks and Communications",
  "Computer Science (miscellaneous)",
  "Computer Science Applications",
  "Computer Vision and Pattern Recognition",
  "Computers in Earth Sciences",
  "Decision Sciences (miscellaneous)",
  "General Computer Science",
  "Hardware and Architecture",
  "Human-Computer Interaction",
  "Information Systems",
  "Information Systems and Management",
  "Logic",
  "Management Information Systems",
  "Signal Processing",
  "Software",
  "Theoretical Computer Science"
 ],
 "mathematics": [
  "Algebra and Number Theory",
  "Analysis",
  "Applied Mathematics",
  "Computational Mathematics",
  "Computational Mechanics",
  "Computational Theory and Mathematics",
  "Decision Sciences (miscellaneous)",
  "Discrete Mathematics and Combinatorics",
  "General Mathematics",
  "Geometry and Topology",
  "Mathematical Physics",
  "Mathematics (miscellaneous)",
  "Numerical Analysis",
  "Statistical and Nonlinear Physics",
  "Statistics",
  "Statistics and Probability"
 ],
 "biology": [
  "Ageing",
  "Agronomy and Crop Science",
  "Anatomy",
  "Animal Science and Zoology",
  "Bioengineering",
  "Biological Psychiatry",
  "Biomaterials",
  "Biophysics",
  "Biotechnology",
  "Cell Biology",
  "Conservation",
  "Decision Sciences (miscellaneous)",
  "Developmental Biology",
  "Embryology",
  "Endocrinology",
  "Equine",
  "Food Animals",
  "Forestry",
  "General Agricultural and Biological Sciences",
  "Genetics",
  "Genetics(clinical)",
  "Gerontology",
  "Horticulture",
  "Insect Science",
  "Molecular Biology",
  "Palaeontology",
  "Parasitology",
  "Physiology",
  "Small Animals",
  "Structural Biology"
 ],
 "biochemistry": [
  "Aquatic Science",
  "Biochemistry",
  "Biological Psychiatry",
  "Clinical Biochemistry",
  "General Biochemistry"
 ],
 "physics": [
  "Acoustics and Ultrasonics",
  "Atomic and Molecular Physics",
  "Biophysics",
  "Colloid and Surface Chemistry",
  "Condensed Matter Physics",
  "Electrochemistry",
  "Electronic",
  "Energy (miscellaneous)",
  "Fluid Flow and Transfer Processes",
  "General Energy",
  "General Physics and Astronomy",
  "Mathematical Physics",
  "Nuclear Energy and Engineering",
  "Nuclear and High Energy Physics",
  "Physical and Theoretical Chemistry",
  "Physics and Astronomy (miscellaneous)",
  "Radiation",
  "Spectroscopy",
  "Surfaces",
  "Surfaces and Interfaces"
 ],
 "materials science": [
  "Biomaterials",
  "Ceramics and Composites",
  "General Materials Science",
  "Materials Chemistry",
  "Materials Science (miscellaneous)",
  "Mechanics of Materials",
  "Metals and Alloys",
  "Polymers and Plastics",
  "Surfaces",
  "Surfaces and Interfaces"
 ],
 "chemistry": [
  "Analytical Chemistry",
  "Biomaterials",
  "Catalysis",
  "Chemical Engineering (miscellaneous)",
  "Chemistry (miscellaneous)",
  "Colloid and Surface Chemistry",
  "Electrochemistry",
  "Environmental Chemistry",
  "Fuel Technology",
  "General Chemical Engineering",
  "General Chemistry",
  "Inorganic Chemistry",
  "Materials Chemistry",
  "Organic Chemistry",
  "Physical and Theoretical Chemistry",
  "Process Chemistry and Technology",
  "Surfaces",
  "Surfaces and Interfaces"
 ],
 "engineering": [
  "Aerospace Engineering",
  "Automotive Engineering",
  "Building and Construction",
  "Chemical Engineering (miscellaneous)",
  "Civil and Structural Engineering",
  "Control and Systems Engineering",
  "Electrical and Electronic Engineering",
  "Electronic",
  "Energy Engineering and Power Technology",
  "Engineering (miscellaneous)",
  "Environmental Engineering",
  "General Chemical Engineering",
  "General Engineering",
  "Geotechnical Engineering and Engineering Geology",
  "Industrial and Manufacturing Engineering",
  "Mechanical Engineering",
  "Nuclear Energy and Engineering",
  "Sensory Systems",
  "Signal Processing",
  "Water Science and Technology"
 ],
 "earth sciences": [
  "Aquatic Science",
  "Atmospheric Science",
  "Computers in Earth Sciences",
  "Conservation",
  "Earth and Planetary Sciences (miscellaneous)",
  "Earth-Surface Processes",
  "Ecological Modelling",
  "Ecology",
  "Economic Geology",
  "Environmental Chemistry",
  "Environmental Science (miscellaneous)",
  "General Earth and Planetary Sciences",
  "General Environmental Science",
  "Geochemistry and Petrology",
  "Geography",
  "Geology",
  "Geophysics",
  "Geotechnical Engineering and Engineering Geology",
  "Global and Planetary Change",
  "Nature and Landscape Conservation",
  "Ocean Engineering",
  "Oceanography",
  "Palaeontology",
  "Plant Science",
  "Pollution",
  "Soil Science",
  "Stratigraphy",
  "Water Science and Technology"
 ],
 "astronomy": [
  "Astronomy and Astrophysics",
  "General Physics and Astronomy",
  "Geochemistry and Petrology",
  "Physics and Astronomy (miscellaneous)",
  "Space and Planetary Science"
 ],
 "biomedical and health sciences": [
  "Advanced and Specialised Nursing",
  "Ageing",
  "Anesthesiology and Pain Medicine",
  "Bioengineering",
  "Biological Psychiatry",
  "Biomaterials",
  "Biomedical Engineering",
  "Cancer Research",
  "Cardiology and Cardiovascular Medicine",
  "Care Planning",
  "Chemical Health and Safety",
  "Chiropractics",
  "Clinical Biochemistry",
  "Clinical Neurology",
  "Clinical Psychology",
  "Complementary and Manual Therapy",
  "Complementary and alternative medicine",
  "Critical Care",
  "Critical Care and Intensive Care Medicine",
  "Dentistry (miscellaneous)",
  "Dermatology",
  "Developmental Neuroscience",
  "Drug Discovery",
  "Drug guides",
  "Emergency",
  "Emergency Medical Services",
  "Emergency Medicine",
  "Endocrine and Autonomic Systems",
  "Endocrinology",
  "Epidemiology",
  "Family Practice",
  "Gastroenterology",
  "General Dentistry",
  "General Health Professions",
  "General Immunology and Microbiology",
  "General Medicine",
  "General Nursing",
  "General Pharmacology",
  "General Veterinary",
  "Genetics",
  "Genetics(clinical)",
  "Geriatrics and Gerontology",
  "Health",
  "Health Informatics",
  "Health Information Management",
  "Health Policy",
  "Health Professions (miscellaneous)",
  "Health(social science)",
  "Hematology",
  "Hepatology",
  "Histology",
  "Immunology",
  "Immunology and Allergy",
  "Immunology and Microbiology",
  "Infectious Diseases",
  "Internal Medicine",
  "LPN and LVN",
  "Life-span and Life-course Studies",
  "Maternity and Midwifery",
  "Medical Assisting and Transcription",
  "Medical Laboratory Technology",
  "Medical\u2013Surgical",
  "Medicine (miscellaneous)",
  "Microbiology (medical)",
  "Molecular Medicine",
  "Nephrology",
  "Nurse Assisting",
  "Nursing (miscellaneous)",
  "Nutrition and Dietetics",
  "Obstetrics and Gynaecology",
  "Occupational Therapy",
  "Oncology",
  "Oncology(nursing)",
  "Ophthalmology",
  "Optometry",
  "Oral Surgery",
  "Orthodontics",
  "Orthopedics and Sports Medicine",
  "Otorhinolaryngology",
  "Pathology and Forensic Medicine",
  "Pediatrics",
  "Periodontics",
  "Pharmaceutical Science",
  "Pharmacology (medical)",
  "Pharmacy",
  "Phychiatric Mental Health",
  "Physical Therapy",
  "Physiology (medical)",
  "Podiatry",
  "Process Chemistry and Technology",
  "Psychiatry and Mental health",
  "Public Health",
  "Pulmonary and Respiratory Medicine",
  "Radiology Nuclear Medicine and imaging",
  "Rehabilitation",
  "Reproductive Medicine",
  "Rheumatology",
  "Sensory Systems",
  "Speech and Hearing",
  "Surgery",
  "Toxicology",
  "Transplantation",
  "Urology",
  "Veterinary (miscalleneous)",
  "Virology"
 ],
 "applied sciences": [
  "Acoustics and Ultrasonics",
  "Agricultural and Biological Sciences (miscellaneous)",
  "Agronomy and Crop Science",
  "Applied Microbiology and Biotechnology",
  "Architecture",
  "Food Science",
  "General Agricultural and Biological Sciences",
  "Information Systems",
  "Library and Information Sciences",
  "Medical Laboratory Technology",
  "Radiological and Ultrasound Technology",
  "Renewable Energy"
 ],
 "pharmacology": [
  "Anesthesiology and Pain Medicine",
  "Clinical Biochemistry",
  "Drug Discovery",
  "Drug guides",
  "General Medicine",
  "Molecular Medicine",
  "Pharmaceutical Science",
  "Pharmacology",
  "Pharmacology (medical)",
  "Pharmacology (nursing)",
  "Pharmacy"
 ],
 "neuroscience": [
  "Behavioral Neuroscience",
  "Biological Psychiatry",
  "Cellular and Molecular Neuroscience",
  "Clinical Neurology",
  "Cognitive Neuroscience",
  "Developmental Neuroscience",
  "General Neuroscience",
  "Neurology",
  "Neuropsychology and Physiological Psychology",
  "Neuroscience (miscellaneous)"
 ],
 "microbiology": [
  "Applied Microbiology and Biotechnology",
  "Immunology and Microbiology",
  "Microbiology",
  "Microbiology (medical)"
 ],
 "behavioural sciences": [
  "Applied Psychology",
  "Community and Home Care",
  "Developmental and Educational Psychology",
  "Experimental and Cognitive Psychology",
  "Fundamentals and skills",
  "General Decision Sciences",
  "General Psychology",
  "Human Factors and Ergonomics",
  "Neuropsychology and Physiological Psychology",
  "Occupational Therapy",
  "Psychology (miscellaneous)",
  "Religious studies",
  "Social Psychology",
  "Sociology and Political Science"
 ],
 "humanities": [
  "Arts and Humanities (miscellaneous)",
  "Classics",
  "Conservation",
  "Cultural Studies",
  "Demography",
  "Education",
  "General Arts and Humanities",
  "History",
  "History and Philosophy of Science",
  "Language and Linguistics",
  "Law",
  "Linguistics and Language",
  "Literature and Literary Theory",
  "Museology",
  "Music",
  "Philosophy",
  "Political Science and International Relations",
  "Religious studies",
  "Visual Arts and Performing Arts"
 ]
};

const root_categories = [
  'multidisciplinary',
  'social sciences',
  'business & management',
  'other',
  'computer science',
  'mathematics',
  'biology',
  'physics',
  'biochemistry',
  'chemistry',
  'materials science',
  'earth sciences',
  'engineering',
  'earth sciences',
  'astronomy',
  'applied sciences',
  'biomedical and health sciences',
  'microbiology',
  'pharmacology',
  'neuroscience',
  'behavioural sciences',
  'humanities',
  'geography',
  'electrical engineering',
  'Civil and Structural Engineering'
];

export default root_categories;
