<template>
    <div class="search-author map-form">
        <section class="author-name">
            <p class="body3">Add author name as it appears in publications</p>
            <b-field
                    label="First Name"
                    :type="firstName.isError ? 'is-danger' : ''"
                    :message="firstName.isError ? firstName.message : ''">
                <b-input size="is-large" placeholder="First Name" v-model="firstName.value"></b-input>
            </b-field>
            <b-field label="Other Name">
                <b-input size="is-large" placeholder="Other Name" v-model="otherName"></b-input>
            </b-field>
            <b-field
                    label="Last Name"
                    :type="lastName.isError ? 'is-danger' : ''"
                    :message="lastName.isError ? lastName.message : ''">
                <b-input size="is-large" placeholder="Last Name" v-model="lastName.value"></b-input>
            </b-field>
        </section>

        <div class="sep"></div>

        <section>
            <p>Select author’s categories of expertise so we can validate the authorship. You can select the main category or be more specific to help us fetch the correct data.</p>
            <SelectGroup v-for="title in rootCategories" key="title" :ref="title" :title="title" :categories="categoriesMap[title]"/>
        </section>

        <button class="button is-large is-fullwidth is-blue" v-on:click="search">Go Fetch!</button>

        <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>
    </div>
</template>

<script>
    import SelectGroup from './form/SelectGroup'
    import CATEGORIES from '../categories'

    export default {
        name: 'author-search',
        props: {
            searchCallback: Function
        },
        data: () => {
            return {
                isLoading: false,
                categoriesMap: CATEGORIES,
                rootCategories: [],
                // q: '',

                firstName: {
                    value: '',
                    message: 'Please type in first name',
                    isError: false,
                },
                lastName: {
                    value: '',
                    message: 'Please type in last name',
                    isError: false,
                },
                otherName: '',
            }
        },

        mounted() {
            for (let c in CATEGORIES) {
                this.rootCategories.push(c);
            }
            this.rootCategories.sort();
        },

        methods: {
            validateForm() {
                let error = false;
                this.firstName.isError = false;
                this.lastName.isError = false;

                if (this.firstName.value === '') {
                    this.firstName.isError = true;
                    error = true;
                }

                if (this.lastName.value === '') {
                    this.lastName.isError = true;
                    error = true;
                }

                return error;
            },

            submitSearch(categories) {
                const params = {
                    first_name: this.firstName.value,
                    last_name: this.lastName.value,
                    other_name: this.otherName,
                    categories: categories
                };

                this.isLoading = true;
                this.$http.post(this.$api.url('/search-author'), params).then(response => {
                    this.isLoading = false;
                    console.log(response.body);
                    
                    if (this.searchCallback != null){
                        this.searchCallback(response.body.aid)
                    }

                }, response => {
                    // error callback
                    this.isLoading = false;
                    console.log(response)
                    this.$toast.open({
                                         duration: 5000,
                                         message: `Error doing search, please try again or contact support`,
                                         type: 'is-danger'
                                     })
                });
            },


            search() {
                if (!this.validateForm()) {
                    const cats = new Set();

                    for (let i in this.rootCategories){
                        const root  = this.rootCategories[i];
                        const selectedCategroies = this.$refs[root][0].selectedCategories();
                        for (let j in selectedCategroies){
                            cats.add(selectedCategroies[j]);
                        }
                    }
                    const categories = [...cats];

                    if (categories.length === 0) {
                        this.$dialog.confirm(
                            {
                                title: 'Not The Usual Mumbo Jumbo!',
                                message: 'Searching without selecting any categories is possible but not recommended. ' +
                                'Choosing the fields of research allows us to identify the right author more accurately. ' +
                                'If you understand it, and still want to continue, click on "Search Anyway"',
                                confirmText: 'Search Anyway',
                                type: 'is-danger',
                                onConfirm: () => this.submitSearch(categories)
                            });
                    } else {
                        this.submitSearch(categories)
                    }

                } else {
                    window.scrollTo(0, 0);
                }
            },

            bla() {
                // console.log(this.$_.snakeCase("categories of expertise "))
                console.log(this.$refs['Business Economics & Management'][0].selectedCategories())
            }
        },

        components: {SelectGroup}
    }
</script>

<style lang="scss" scoped>
    @import "../assets/scss/vars";

    .search-author {

        .sep {
            width: 100%;
            height: 1px;
            background-color: $light-grey;
            margin: $space-4 0 $space-3 0;
        }

        .button {
            margin-top: $space-4;
        }
    }

</style>