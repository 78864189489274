/**
 * Created by IntelliJ IDEA.
 * User: uzix
 * Date: 04/10/2018
 * Time: 09:17
 */

const MapSession = {
    getCurrentUser: function () {
        if (sessionStorage) {
             return sessionStorage.getItem('oid');
        }

        return null;
    },

    isloggedIn: function () {
        return this.getCurrentUser() !== null;
    },

    clear: function() {
        if (sessionStorage) {
            sessionStorage.clear()
        }
    }
};

export default MapSession;