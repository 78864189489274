<template>
    <div class="container columns contact-page mobile-padding map-form">
        <div class="column is-8 is-offset-2">
            <h1>Contact</h1>
            <form id="contact-form" name="contact"  method="POST" v-on:submit.prevent="onSubmit" data-netlify="true" netlify-honeypot="bot-field">
                <input type="hidden" name="form-name" value="contact">
                <input type="hidden" name="bot-field">

                <b-message type="is-success" v-if="success">
                    Thank you for contacting us!
                </b-message>

                <b-field
                        label="Name"
                        :type="name.isError ? 'is-danger' : ''"
                        :message="name.isError ? name.message : ''"
                >
                    <b-input size="is-large"
                             placeholder="Name"
                             type="text"
                             name="name"
                             v-model="name.value"></b-input>
                </b-field>

                <b-field
                        label="Email"
                        :type="email.isError ? 'is-danger' : ''"
                        :message="email.isError ? email.message : ''"
                >
                    <b-input size="is-large"
                             placeholder="Email"
                             type="email"
                             name="email"
                             v-model="email.value"></b-input>
                </b-field>

                <b-field
                        label="Message"
                        :type="msg.isError ? 'is-danger' : ''"
                        :message="msg.isError ? msg.message : ''"
                >
                    <b-input size="is-large"
                             type="textarea"
                             name="message"
                             placeholder="Write us something…"></b-input>
                </b-field>

                <div data-netlify-recaptcha="true"></div>

                <div class="is-pulled-right"><button class="button cta-button is-medium">Send</button></div>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ContactPage',
        metaInfo: {
            title: 'Contact us',
        },

        data: () => {
            return {
                success: false,
                email: {
                    value: '',
                    message: 'Please type in a valid email',
                    isError: false,
                },
                name: {
                    value: '',
                    message: 'Please type in your name',
                    isError: false,
                },
                msg: {
                    value: '',
                    message: 'Please write something',
                    isError: false,
                },
            }
        },

        methods: {
            resetForm: function () {
                this.name.isError = false;
                this.email.isError = false;
                this.msg.isError = false;
                this.name.value = '';
                this.email.value = '';
                this.msg.value = '';

            },

            onSubmit: function (elem) {
                const form = this.$el.querySelector('#contact-form');
                const fields = this.$el.querySelectorAll('#contact-form input, #contact-form textarea');
                const formData = new FormData();

                this.name.isError = false;
                this.email.isError = false;
                this.msg.isError = false;

                for (let i = 0; i < fields.length; i++) {
                    if (fields[i]['name'] === 'bot-field') {
                        continue;
                    }

                    if (fields[i]['name'] === 'name' && fields[i]['value'] === '') {
                        this.name.isError = true;
                    }

                    if (fields[i]['name'] === 'email' && fields[i]['value'] === '') {
                        this.email.isError = true;
                    }

                    if (fields[i]['name'] === 'message' && fields[i]['value'] === '') {
                        this.msg.isError = true;
                    }

                    formData.append(fields[i]['name'], fields[i]['value']);
                }

                if (this.name.isError || this.email.isError || this.msg.isError) {
                    return;
                }

                this.$http.post(form['action'], formData).then(response => {
                                                                   this.success = true;
                                                                   this.resetForm();
                                                               },
                                                               response => {
                                                                   this.success = true;
                                                                   this.resetForm();
                                                               })
            }
        }
    }
</script>
