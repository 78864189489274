export const ChartColors = {
    darkGrey: '#363636',
    mediumGrey2: '#9b9b9b',
    lightGrey: '#e9e9e9',
    blue: '#5a97f1',
    blueOpacity: 'rgba(90, 151, 241, 0.12)',
    blueOpacityValue: 0.12,
    green: '#6be8a9',
    greenOpacity: 'rgba(107, 232, 169, 0.13)',
    greenOpacityValue: 0.13,
    turquoiseBlue: '#57dce4',
    turquoiseOpacity: 'rgba(87, 220, 228, 0.15)',
    turquoiseOpacityValue: 0.15,
    orange: '#f19d94',
    orangeOpacity: 'rgba(241, 157, 148, 0.08)',
    orangeOpacityValue: 0.08,
    classicOrange: '#f3ac6a',
    classicOrangeOpacity: 'rgba(243, 172, 106, 0.1)',
    classicOrangeOpacityValue: 0.1,
    yellow: '#f5d131',
    yellowOpacity: 'rgba(245, 209, 49, 0.11)',
    yellowOpacityValue: 0.11,
};

export default ChartColors;