<template>
    <div>
        <div v-if="authorDataReady">
            <!--<nav class="navbar" role="navigation" aria-label="main navigation">-->
            <!--<div class="navbar-brand">-->
            <!--<img src="../assets/settings.svg" v-on:click="settingVisible = !settingVisible"/>-->
            <!--<img src="../assets/share.svg"/>-->
            <!--<img src="../assets/print.svg"/>-->
            <!--</div>-->
            <!--</nav>-->

            <ChartsSettings
                    ref="settings"
                    :visible="settingVisible"
                    :initials="author"
                    :articles="articles"
                    :activeYearsRange="articlesActiveRange"
                    :aid="$route.params.id"
                    v-on:close="settingVisible = false"
                    v-on:refresh="refreshCharts"
                    v-on:range-changed="yearsChanged"
                    v-on:set-articles="setArticles"
            ></ChartsSettings>

            <div class="section charts mobile-padding">
                <div class="level">
                    <div class="level-left"><h1>{{author}} Academic Profile</h1></div>
                    <div class="level-right menu">
                        <!--<div class="field">-->
                            <!--<b-switch :disabled="compareDisabled" v-on:input="compare">Compare With Field Leaders</b-switch>-->
                        <!--</div>-->

                        <b-dropdown position="is-bottom-left">
                            <div class="dots" slot="trigger"></div>

                            <b-dropdown-item v-on:click="settingVisible = !settingVisible"><span class="icon"><img src="../assets/settings.svg"/></span>Settings</b-dropdown-item>
                            <b-dropdown-item v-on:click="shareDialogVisible = !shareDialogVisible"><span class="icon"><img src="../assets/share.svg"/></span>Share</b-dropdown-item>
                            <b-dropdown-item v-on:click="printPage"><span class="icon"><img src="../assets/print.svg"/></span>Print</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </div>

                <b-tabs v-model="activeTab" size="is-medium">
                    <b-tab-item label="Descriptive">
                        <div class="chart-list">
                            <SingleChart
                                    title="Average no. of Articles per year"
                                    description="Accumulated no. Articles divided by the number of years. This metric represents the change in average productivity over the years."
                                    color="blue"
                                    :options="charts['avg-article-chart']"
                                    ref="avg-article-chart"/>
                            <SingleChart
                                    title="No. of Authors"
                                    description="The number of articles for each category of number of Authors. The distribution of articles according to the number of authors helps to differentiate between Collaborators and Individualists."
                                    color="purple"
                                    :options="charts['author-count-chart']"
                                    ref="author-count-chart"/>
                            <SingleChart
                                    title="First Author"
                                    description=""
                                    color="purple"
                                    :options="charts['first-author-chart']"
                                    ref="first-author-chart"/>
                            <SingleChart
                                    title="Sum of First Author"
                                    description=""
                                    color="turquoise"
                                    :options="charts['sum-first-author-chart']"
                                    ref="sum-first-author-chart"/>
                            <SingleChart
                                    title="Single Authored/Year"
                                    description="Single authored articles best identify the own author contribution. Some disciplines requiring a maximal number of such articles, others viewing articles written with supervised students as of equal importance."
                                    color="blue"
                                    :options="charts['sole-author-chart']"
                                    ref="sole-author-chart"/>
                            <SingleChart
                                    title="Sum of Single Author"
                                    description=""
                                    color="turquoise"
                                    :options="charts['sum-sole-author-chart']"
                                    ref="sum-sole-author-chart"/>
                            <SingleChart
                                    title="Cumulative Sum of Citations"
                                    description="This metric describes the progress in the total scientific impact."
                                    color="blue"
                                    className="last"
                                    :options="charts['sum-citations-chart']"
                                    ref="sum-citations-chart"/>
                        </div>
                    </b-tab-item>
                    <b-tab-item label="Analysis">
                        <div class="force-page-break"></div>

                        <div class="chart full">
                            <div class="chart-meta">
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <h2>Relative Contribution</h2>
                                    </div>
                                    <div class="level-right"><a href="/faq#what-is-rc" target="_blank"><img src="../assets/info.svg"></a></div>
                                </div>
                            </div>
                            <highcharts :options="charts['article-count-chart']" ref="article-count-chart"></highcharts>
                            <div class="chart-meta">
                                <div class="columns is-gapless">
                                    <div class="column is-two-thirds">
                                        <p class="body1">
                                            Accumulated Sum of Articles from the beginning of the Academic Career.
                                            A simple metric is representing the total number of articles up to each year.
                                            Express change in publication rates.

                                            <br/><br/>RC-A = Relative Contribution of first author only
                                            <br/>RC-B = Relative Contribution of first and last author

                                            <br/><br/><a href="/faq#what-is-rc" target="_blank">Read more about Relative Contribution</a>.
                                        </p>
                                    </div>
                                    <div class="column">
                                        <ul class="legend">
                                            <li class="body2" v-bind:class="{'is-off': !legends['article-count-chart'][0]}" v-on:click="tickLegend('article-count-chart', 0)">Accumulated no. of Articles
                                            </li>
                                            <li class="body2 green" v-bind:class="{'is-off': !legends['article-count-chart'][1]}" v-on:click="tickLegend('article-count-chart', 1)">Accumulated no. of
                                                Articles
                                                according to RC-A
                                            </li>
                                            <li class="body2 turquoise" v-bind:class="{'is-off': !legends['article-count-chart'][2]}" v-on:click="tickLegend('article-count-chart', 2)">Accumulated no. of
                                                Articles
                                                according to RC-B
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart full">
                            <div class="chart-meta">
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <h2>Accumulated no. of citations/year since publication</h2>
                                    </div>
                                    <div class="level-right"><img src="../assets/info.svg"></div>
                                </div>
                            </div>
                            <highcharts :options="charts['citation-per-year-chart']" ref="citation-per-year-chart"></highcharts>
                            <div class="chart-meta">
                                <div class="columns is-gapless">
                                    <div class="column is-two-thirds">
                                        <p class="body1">Sum of citations for articles published each year. <br/>
                                            This metric helps to identify years and periods producing research of high impact and trends of change increase in scientific impact.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart full">
                            <div class="chart-meta">
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <h2>Accumulated no. of citations/year since publication and Relative Contribution</h2>
                                    </div>
                                    <div class="level-right"><img src="../assets/info.svg"></div>
                                </div>
                            </div>
                            <highcharts :options="charts['citation-contrib-chart']" ref="citation-contrib-chart"></highcharts>
                            <div class="chart-meta">
                                <div class="columns is-gapless">
                                    <div class="column is-two-thirds">
                                        <p class="body1">
                                            This represents the credit that the author can get on the amount of citations obtained.

                                            <br/><br/>RC-A = Relative Contribution of first author only
                                            <br/>RC-B = Relative Contribution of first and last author
                                        </p>
                                    </div>
                                    <div class="column">
                                        <ul class="legend">
                                            <li class="body2 green" v-bind:class="{'is-off': !legends['citation-contrib-chart'][0]}" v-on:click="tickLegend('citation-contrib-chart', 0)">Citations/year
                                                according
                                                to RC-A.
                                            </li>
                                            <li class="body2 turquoise" v-bind:class="{'is-off': !legends['citation-contrib-chart'][1]}" v-on:click="tickLegend('citation-contrib-chart', 1)">Citations/year
                                                according
                                                to RC-B
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="chart full">
                            <div class="chart-meta">
                                <div class="level is-mobile">
                                    <div class="level-left">
                                        <h2>Accumulated no. of citations/year since publication, Relative Contribution and Journal</h2>
                                    </div>
                                    <div class="level-right"><img src="../assets/info.svg"></div>
                                </div>
                            </div>
                            <highcharts :options="charts['citation-contrib-journal-chart']" ref="citation-contrib-journal-chart"></highcharts>
                            <div class="chart-meta">
                                <div class="columns is-gapless">
                                    <div class="column is-two-thirds">
                                        <p class="body1">
                                            This represents the combination of the three parameters representing both the contributions of the author and his achievements.

                                            <br/><br/>RC-A = Relative Contribution of first author only
                                            <br/>RC-B = Relative Contribution of first and last author
                                        </p>
                                    </div>
                                    <div class="column">
                                        <ul class="legend">
                                            <li class="body2 turquoise" v-bind:class="{'is-off': !legends['citation-contrib-journal-chart'][0]}" v-on:click="tickLegend('citation-contrib-journal-chart', 0)">
                                                Citations/year according to RC-A and Journal
                                            </li>
                                            <li class="body2" v-bind:class="{'is-off': !legends['citation-contrib-journal-chart'][1]}" v-on:click="tickLegend('citation-contrib-journal-chart', 1)">
                                                Citations/year according to RC-B and journal
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </b-tab-item>
                    <b-tab-item label="Compare" :disabled="compareDisabled">
                        <div class="chart-list">


                            
                            <SingleChart
                                    title="Relative Contribution"
                                    description="Accumulated Sum of Articles from the beginning of the Academic Career.
                                                A simple metric is representing the total number of articles up to each year.
                                                Express change in publication rates."
                                    color="blue"
                                    :options="compareCharts['article-count-chart']"
                                    ref="compare-article-count-chart"/>

                            <SingleChart
                                    title="Relative Contribution RC-A"
                                    description=""
                                    color="blue"
                                    :options="compareCharts['article-count-rc-a-chart']"
                                    ref="compare-article-count-rc-a-chart"/>

                            <SingleChart
                                    title="Relative Contribution RC-B"
                                    description=""
                                    color="blue"
                                    :options="compareCharts['article-count-rc-b-chart']"
                                    ref="compare-article-count-rc-b-chart"/>

                            <SingleChart
                                    title="Cumulative Sum of Citations"
                                    description="This metric describes the progress in the total scientific impact."
                                    color="blue"
                                    :options="compareCharts['sum-citations-chart']"
                                    ref="compare-sum-citations-chart"/>

                            <SingleChart
                                    title="Accumulated citations/year since publication and RC-A"
                                    description="This represents the credit that the author can get on the amount of citations obtained."
                                    color="blue"
                                    :options="compareCharts['citation-contrib-rc-a-chart']"
                                    ref="compare-citation-contrib-rc-a-chart"/>

                            <SingleChart
                                    title="Accumulated citations/year since publication and RC-B"
                                    description="This represents the credit that the author can get on the amount of citations obtained."
                                    color="blue"
                                    :options="compareCharts['citation-contrib-rc-b-chart']"
                                    ref="compare-citation-contrib-rc-b-chart"/>

                            <SingleChart
                                    title="Accumulated citations/year since publication, RC-A and Journal"
                                    description="This represents the combination of the three parameters representing both the contributions of the author and his achievements."
                                    color="blue"
                                    :options="compareCharts['citation-contrib-journal-rc-a-chart']"
                                    ref="compare-citation-contrib-journal-rc-a-chart"/>

                            <SingleChart
                                    title="Accumulated citations/year since publication, RC-B and Journal"
                                    description="This represents the combination of the three parameters representing both the contributions of the author and his achievements."
                                    color="blue"
                                    :options="compareCharts['citation-contrib-journal-rc-b-chart']"
                                    ref="compare-citation-contrib-journal-rc-b-chart"/>
                            
                        </div>
                    </b-tab-item>
                </b-tabs>

                <b-modal :active.sync="shareDialogVisible" :width="640" scroll="keep" :canCancel="true">
                    <div class="card share">
                        <div class="card-content">
                            <h2>Share Profile URL</h2>
                            <b-field grouped>
                                <b-input placeholder="URL" type="url" id="share-url" :value="shareUrl" v-on:click.native="copyShareUrl" readonly expanded></b-input>
                                <p class="control">
                                    <a class="button is-purple" @click="copyShareUrl">Copy</a>
                                </p>
                            </b-field>
                        </div>
                    </div>
                </b-modal>
            </div>
        </div>
        <div class="section" v-else>
            <div class="has-text-centered" v-if="isLoading"></div>
            <div class="has-text-centered" v-else>
                author data is still being processed, page will reload when its ready...
                <Spinner/>
                <!--<b-loading :is-full-page="false" :active="true"></b-loading>-->
            </div>
        </div>

        <div class="charts-loading" v-if="isLoading">
            <b-loading :is-full-page="false" :active.sync="isLoading"></b-loading>
        </div>
    </div>
</template>

<script>
    import ChartsSettings from '../components/ChartsSettings'
    import Spinner from '../components/Spinner'
    import SingleChart from '../components/SingleChart'
    import {setupChartsData} from '../charts/utils';
    import {ChartsConfig, CompareConfig} from '../charts/config';

    export default {
        name: 'ChartsPage',

        data: () => {
            return {
                activeTab: 0,
                authorDataReady: false,
                isLoading: false,
                settingVisible: false,
                shareDialogVisible: false,
                articles: [],
                leaderArticles: [],
                author: null,
                articlesActiveRange: {min: 0, max: 4000},
                legends: {
                    'article-count-chart': [true, true, true],
                    'citation-contrib-chart': [true, true],
                    'citation-contrib-journal-chart': [true, true],
                },
                compareDisabled: true,
                // isCompareOn: false,
                charts: ChartsConfig,
                compareCharts: CompareConfig,
            }
        },

        computed: {
            shareUrl: function () {
                return window.location.href;
            },
        },

        mounted() {
            this.loadAuthorData(true);
        },

        methods: {
            loadAuthorData(showLoadingSpinner) {
                if (showLoadingSpinner) this.isLoading = true;

                this.$http.get(this.$api.url('/profiles/chart-data?pid=' + this.$route.params.id)).then(response => {
                    this.isLoading = false;

                    if (response.status === 204) {
                        //author data is still not ready
                        this.authorDataReady = false;
                        window.setTimeout(() => {
                            this.loadAuthorData(false);
                        }, 10000)
                    } else {
                        // success
                        this.authorDataReady = true;
                        this.author = response.body.author;
                        this.articlesActiveRange = response.body.years_range || {min: 0, max: 4000};

                        if (response.body.leader_articles !== null ||
                            response.body.leader_articles !== undefined ||
                            response.body.leader_articles.length > 0) {

                            this.leaderArticles = response.body.leader_articles;
                            this.compareDisabled = false;
                        }

                        this.setArticles(response.body.articles);
                    }


                }, response => {
                    // error callback
                    console.log(response)
                    this.isLoading = false
                });
            },

            recalcCharts() {
                this.charts = setupChartsData(this.articles, this.leaderArticles, this.charts, this.compareCharts);
                this.$forceUpdate();
            },

            tickLegend(chartName, indx) {
                const chart = this.$refs[chartName];

                const series = chart.chart.series[indx];
                if (series.visible) {
                    chart.chart.series[indx].hide();
                    this.legends[chartName][indx] = false;
                } else {
                    chart.chart.series[indx].show();
                    this.legends[chartName][indx] = true;
                }

                this.$forceUpdate();
            },

            refreshCharts(article) {
                this.isLoading = true;
                this.recalcCharts();
                const params = {
                    pid: this.$route.params.id,
                    oid: article.oid,
                    active: article.active,
                };

                this.$http.post(this.$api.url('/articles/set-state'), params).then(response => {
                    this.setArticles(response.body.articles);
                    this.isLoading = false;

                }, response => {
                    // error callback
                    this.isLoading = false;
                    console.log(response)
                });
            },

            recalcDisabledArticles() {
                this.articles.forEach((article) => {

                    article.disabled = article.year < this.articlesActiveRange.min ||
                        article.year > this.articlesActiveRange.max;
                });
            },

            yearsChanged(range) {
                this.articlesActiveRange = range;

                const params = {
                    pid: this.$route.params.id,
                    years_range: range,
                };

                this.isLoading = true;
                this.$http.post(this.$api.url('/articles/set-years-range'), params).then(response => {
                    this.setArticles(response.body.articles);
                    this.isLoading = false;

                }, response => {
                    // error callback
                    this.isLoading = false;
                    console.log(response)
                });
            },

            setArticles(articles, years_range) {

                if (years_range !== undefined) {
                    this.articlesActiveRange = years_range;
                }

                this.articles = articles;

                this.recalcDisabledArticles();
                this.recalcCharts();
            },

            copyShareUrl() {
                const share = document.getElementById('share-url');
                share.select();
                document.execCommand('copy');
                this.$toast.open(
                    {
                        message: 'URL copied to clipboard!',
                        type: 'is-success'
                    })
            },

            printPage() {
                window.print();
            },

            tickChart(chart, indx, value) {
                if (value) {
                    chart.series[indx].show()
                } else {
                    chart.series[indx].hide()
                }
            },
        },

        props: {
            msg: String
        },

        components: {
            ChartsSettings,
            Spinner,
            SingleChart,
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--<style scoped lang="scss">-->
<!--</style>-->
